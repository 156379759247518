import React, {useEffect, useRef, useState} from 'react';
import {shallowEqual, useSelector} from "react-redux";
import Portal from "../portal";
import {FiChevronLeft, FiChevronRight, FiX} from "react-icons/fi";
import ReactTooltip from "react-tooltip";


export const ImageGallery = ({
                                 images,
                                 showTitle = false,
                                 onDelete = null
                             }) => {

    let [selectedImage, setSelectedImage] = useState(0);
    let [open, setOpen] = useState(false);

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.palettes,
        }),
        shallowEqual
    )
    let {background} = {
        ...palettes
    }


    const modalRef = useRef(null)

    const canNext = () => {
        return selectedImage + 1 < images.length;
    }

    const canPrevious = () => {
        return selectedImage - 1 >= 0;
    }

    const goNext = () => {
        if (canNext()) setSelectedImage(selectedImage + 1);
    }

    const goPrevious = () => {
        if (canPrevious()) setSelectedImage(selectedImage - 1);
    }

    const hide = () => {
        setOpen(false)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open)
        }
        const handleKeyPress = (event) => {
            if (event.key) {
                switch (event.key) {
                    case "Escape":
                    case "Esc":
                        hide();
                        break;
                    case "ArrowLeft":
                        goPrevious()
                        break;
                    case "ArrowRight":
                        goNext();
                        break;
                }
                if (event.key === "Escape" || event.key === "Esc") {

                }
            } else {
                if (event.keyCode === 27) {
                    hide();
                }
            }

        }

        document.addEventListener("keydown", handleKeyPress)
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener("keydown", handleKeyPress)
        }

    }, [open, modalRef, images])

    return (
        <>
            <div className={"grid lg:grid-cols-4 grid-cols-2 gap-2 mb-2 items-center"}>
                {images && images.map((item, index) => (
                    <div className={"flex flex-col mx-auto"} key={index}>
                        <img src={item.src} className={"rounded border-4 border-legendyColor h-32 object-cover mx-auto"}
                             style={{cursor: "zoom-in"}}
                             alt={item.src} onClick={() => {
                            setSelectedImage(index);
                            setOpen(true);

                        }}/>
                        <div
                            className={"mt-1 text-legendyColor mx-auto"}>{showTitle ? item.title : "#" + (index + 1)} {onDelete &&
                            <button
                                onClick={() => {
                                    if (onDelete) {
                                        onDelete(index)
                                    }
                                }}>
                                <span className={"mt-4"}> <FiX size={18} color={"red"}/></span>
                            </button>}</div>
                    </div>
                ))}
            </div>
            {open && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                         data-background={background}>
                        <div className="relative min-w-sm w-full h-full mx-auto bg-black bg-opacity-50">
                            {images[selectedImage] && (
                                <div className={"lg:w-1/2 opacity-100 grid h-screen place-items-center mx-auto"} style={{
                                    backgroundImage: `url("${images[selectedImage].src}")`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center"
                                }}>
                                </div>
                            )}
                        </div>
                        <div className={"mb-5 text-xl text-white mx-auto fixed bottom-0"}>
                            {showTitle ? images[selectedImage].title : "#" + (selectedImage + 1)}
                        </div>
                        <button
                            className="fixed top-0 right-0 opacity-100"
                            onClick={hide}>
                            <FiX size={50} color={"red"} className="stroke-current"/>
                        </button>
                        {canNext() &&
                            <button className={"absolute inset-y-auto right-0 mr-10"} onClick={() => goNext()}>
                                <FiChevronRight color={"white"} size={80}
                                                className={"border-2 rounded border-white bg-legendyColor bg-opacity-75"}/>

                            </button>}

                        {canPrevious() &&
                            <button className={"absolute inset-y-auto left-0 ml-10"} onClick={() => goPrevious()}>
                                <FiChevronLeft color={"white"} size={80}
                                               className={"border-2 rounded border-white bg-legendyColor bg-opacity-75"}/>

                            </button>}


                    </div>
                </Portal>)}
        </>
    )
}

