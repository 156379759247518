import React, {Fragment, useRef} from "react";
import {Field} from "react-final-form"
import PropTypes from "prop-types"
import {validate} from "email-validator";


const EmailField = (props) => {

    const errorMessage = (message) => {
        return <div className={"flex flew-row text-red-500"}> {message}</div>;
    }

    const renderError = (_props) => {
        if (_props.meta.error && _props.meta.touched) {
            if (_props.input.value === "") return errorMessage("Povinné pole")
            if (!validate(_props.input.value)) return errorMessage("Špatný e-mail")
            if (_props.input.value && props.maxLength && _props.input.value.length >= props.maxLength) return errorMessage(`Zadejte max. ${props.maxLength} znaků.`)
        }
        return null;
    }

    const _renderClassName = (meta, value) => {
        if (props.disabled) return 'form-input';
        let className = `form-input `;
        if (meta.touched && meta.error) {
            className += "border-red-500 ";
        } else if (value && props.minLength && value.length < props.minLength) {
            className += "border-red-500 ";
        } else if (value && props.maxLength && value.length > props.maxLength) {
            className += "border-red-500 ";
        } else if (props.checkWithValue && value !== props.checkWithValue) {
            className += "border-red-500 ";
        } else if (value) {
            className += "border-green-500 ";
        }

        return className;
    };

    return (
        <Field name={props.name} {...props} validate={
            value => {
                if (props.isMandatory && !value) return true;
                if (props.maxLength && value && value.length >= props.maxLength) return true;

                if (typeof value === 'string' && value !== "") return !validate(value)
            }
        } render={_props => {
            return (
                <div className={"form-element w-full p-1"}>
                    {props.label !== false ?
                        <div className={"form-label font-bold"}>{props.label + ": "}{props.isMandatory &&
                            <sup className={"text-legendyColor"}>*</sup>}</div> : null}
                    <input type={"email"} {..._props.input}
                           autoComplete={props.autocomplete}
                           className={_renderClassName(_props.meta, _props.input.value)}
                           placeholder={props.placeholder} disabled={props.disabled}
                           maxLength={props.maxLength}
                           onKeyPress={({key}) => {
                               if (key === "Enter") {
                                   if (props.onEnter) props.onEnter()
                               }
                           }}
                           onChange={(e) => {
                               _props.input.onChange(e.target.value);
                               if (props.onChange) {
                                   props.onChange(props.name, e.target.value);
                               }
                           }}
                           data-test={"writeYourEmailInput"}
                    />
                    <div className={"text-sm pt-1"}>
                        {renderError(_props)}
                    </div>
                </div>
            )
        }}/>
    )
}

EmailField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    inputStyle: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isMandatory: PropTypes.bool.isRequired,
    maxLength: PropTypes.number
}

export default EmailField;
