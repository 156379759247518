import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";
import {FiArrowUp} from "react-icons/all";

const InputField = (props) => {


    const errorMessage = (message) => {
        return (
            <div className={"flex flew-row text-red-500"}>
                <FiArrowUp/> {message}
            </div>
        );
    };

    const renderError = (_props) => {
        if (_props.meta.error && _props.meta.touched) {
            if (!_props.input.value || _props.input.value === "") return <div className={"text-red-500"}>Vyberte</div>;
        }
        return null;
    };

    const _renderClassName = (meta) => {
        let className = `form-input `;
        if (meta.touched && meta.error) {
            className += "border-red-500 ";
        }

        return className;
    };

    return (
        <Field
            name={props.name}
            validate={(value) => {
                if (props.isMandatory) {
                    if (!value && value !== 0) return true;
                }
            }}
            render={(_props) => {
                return (
                    <div className={"form-element w-full p-1 flex-col items-center justify-center"}>
                        <div className={"flex flex-row form-element-inline"}>
                            <input type="radio" id={props.id} value={props.value} disabled={props.disabled}
                                   name={props.group}
                                   onClick={() => _props.input.onChange(props.value)}
                                   defaultChecked={props.defaultChecked}/>
                            <label htmlFor={props.id} className={"cursor-pointer pl-2 "}> {props.label} </label>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"text-sm pt-1"}> {renderError(_props)} </div>
                        </div>
                    </div>
                );
            }}
        />
    );
};

InputField.defaultProps = {
    inputType: "text",
    disabled: false,
};

InputField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    inputStyle: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    showError: PropTypes.bool,
    mandatory: PropTypes.bool,
    isMandatory: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    dataTest: PropTypes.string,
    onEnter: PropTypes.func,
    id: PropTypes.string,
    inputType: PropTypes.oneOf(["text", "number", "password", "radio"]),
};

export default InputField;
