import React, {useEffect, useState} from "react";
import SelectField from "../../components/FinalForm/SelectField";
import SelectMultiField from "../../components/FinalForm/SelectMultiField";
import {apiGetSection} from "./Actions";
import Toast from "cogo-toast";
import {apiGetRegisterSection} from "../Registration/Actions";

export const SectionSelectField = ({label,name,isMulti=false,isMandatory=false,onChange=null,defaultValue=null,type="basic",placeHolder,disabled=false}) =>{

    let [data,setData] = useState([]);

    const onSuccess = (data) => {
        let array = [];
        data.map((item, index) => {
            array.push({value: item.id, label: item.name})
        })
        if(type==="filter"){
            array.push({value:-1,label:"Bez sekce"})
        }
        setData(array);

    };
    const onError = (err) => {
        Toast.error("Nepodařilo se načíst sekce.");
        setData([]);
    };
    useEffect(()=>{
        if(type==="register"){
            apiGetRegisterSection(onSuccess,onError)
        }else{
            apiGetSection(onSuccess,onError);
        }


    },[])

    if(isMulti) return<SelectMultiField placeholder={placeHolder} disabled={disabled} defaultValue={defaultValue} value={null} label={label} name={name} isMandatory={isMandatory} options={data} onChange={onChange}></SelectMultiField>
    else return (
        <SelectField placeholder={placeHolder} defaultValue={defaultValue} name={name} options={data} value={null} label={label}
                     isMandatory={isMandatory} onChange={onChange} disabled={disabled} />
    );
}
