import React from "react";
import ReactTooltip from "react-tooltip";
import {
    AiFillAlert,
    AiFillBug,
    AiFillCheckCircle, AiFillDelete, AiFillDiff, AiFillDislike, AiFillInfoCircle, AiFillLike, AiFillWarning,
    BsPlusCircleFill,
    BsXCircleFill, FaCar, FaCaravan, FaMotorcycle, FaTractor, FaTruck, FiEdit, FiTruck,
    HiDotsCircleHorizontal, RiCaravanLine, RiCarLine, RiMotorbikeLine, RiQuestionLine, RiTruckLine
} from "react-icons/all";
import {Link} from "react-router-dom";
import {FiPlus} from "react-icons/fi";
import {shallowEqual, useSelector} from "react-redux";
import moment from "moment/moment";


const ExponatInfo = ({exponat}) => {
    return (
        <>
            <div className={"flex lg:flex-row flex-col flex-shrink-0 p-5 lg:space-x-6 space-y-4"}>
                <div className={"lg:w-1/12 w-full"}>{getImg(exponat)}</div>
                <div className={"grid lg:grid-cols-4 grid-cols-2 gap-5 lg:w-11/12 w-full"}>
                    <div className={"flex flex-col"}>
                        <span className={"text-grey-700 uppercase mb-3"}>Název exponátu</span>
                        <span className={"text-xl text-black"}>{exponat.nameExponat}</span>
                    </div>
                    <div className={"flex flex-col"}>
                        <span className={"text-grey-700 uppercase mb-3"}>Rok výroby</span>
                        <span className={"text-xl text-black"}>{exponat.manufactureYear}</span>
                    </div>
                    <div className={"flex flex-col"}>
                        <span className={"text-grey-700 uppercase mb-3"}>Stav</span>
                        <span className={"text-xl text-black"}>{getState(exponat)}</span>
                    </div>
                    <div className={"flex flex-col"}>
                        <span className={"text-grey-700 uppercase mb-3"}>Možnosti</span>
                        <span className={"text-xl text-black"}><OptionsButton id={exponat.id}
                                                                              inActiveAction={exponat.inActiveAction}/></span>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ExponatInfo;

const Item = ({title, value}) => {
    return <div className={"w-full flex flex-col space-y-2"}>
        <div className={"text-grey-400 text-lg uppercase"}>{title}</div>
        <div className={"text-xl text-black"}>{value}</div>
    </div>
}

const OptionsButton = ({id, inActiveAction}) => {
    const {activeAction} = useSelector(
        state => (state.activeAction),
        shallowEqual
    )
    return (
        <div className={"flex flex-row space-x-2"}>
            {!inActiveAction ?
                <Link to={"/account/exhibit/action/" + id}>
                    <button data-tip={"Přihlásit na ročník "+activeAction.name} disabled={inActiveAction}
                            className={"btn btn-default rounded btn-icon bg-green-500 hover:bg-green-400 space-x-2 mt-1 px-5 text-white "}>
                        <FiPlus size={20}
                                className={"mr-1"}/> Přihlásit
                    </button>
                    <ReactTooltip
                        html={true}/>
                </Link>
                :
                <Link to={"/account/exhibit/detail/" + id}>
                    <button data-tip={"Detail"}
                            className={"btn btn-default rounded btn-icon bg-grey-500 hover:bg-grey-400 space-x-2 mt-1 px-5 text-white "}>
                        <FiEdit size={20} className={"mr-1"}/> Detail
                    </button>
                    <ReactTooltip/>
                </Link>

            }
        </div>
    )
}

const getImg = (exponat) => {
    let toolTip = "";
    switch (exponat.type) {
        case 0:
            toolTip = "Automobil"
            return <>
                <div style={{
                    backgroundImage: `url(/assets/automobil.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center"
                }} className={"w-full p-10"} data-tip={toolTip}>
                </div>
                <ReactTooltip
                    html={true}/></>;
        case 1:
            toolTip = "Motocykl"
            return <>
                <div style={{
                    backgroundImage: `url(/assets/motocykl.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center"
                }} className={"w-full p-10"} data-tip={toolTip}>
                </div>
                <ReactTooltip
                    html={true}/></>;
        case 2:
            toolTip = "Karavan"
            return <>
                <div style={{
                    backgroundImage: `url(/assets/karavan.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center"
                }} className={"w-full p-10"} data-tip={toolTip}>
                </div>
                <ReactTooltip
                    html={true}/></>;
        case 3:
            toolTip = "Nákladní auto"
            return <>
                <div style={{
                    backgroundImage: `url(/assets/nakladni-vozidlo.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center"
                }} className={"w-full p-10"} data-tip={toolTip}>
                </div>
                <ReactTooltip
                    html={true}/></>;
        case 4:
            toolTip = "Jiný"
            return <>
                <div style={{
                    backgroundImage: `url(/assets/jine.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center"
                }} className={"w-full p-10"} data-tip={toolTip}>
                </div>
                <ReactTooltip
                    html={true}/></>;
        default:
            return ""

    }
}

const getState = (exponat) => {
    let toolTip = "";
    if (!exponat.inActiveAction) {
        return "Nepřihlášen"
    }
    switch (exponat.detail.state) {
        case 0:
            toolTip = "Čeká na schválení";
            return <><AiFillInfoCircle style={{color: "mediumblue"}} size={25} data-tip={toolTip}/><ReactTooltip
                html={true}/></>
        case 1:
            toolTip = "Schváleno"
            return <><AiFillLike style={{color: "green"}} size={25} data-tip={toolTip}/><ReactTooltip
                html={true}/></>
        case 2:
            toolTip = "Je potřeba doplnit informace"
            return <><AiFillWarning style={{color: "orange"}} size={25} data-tip={toolTip}/><ReactTooltip
                html={true}/> </>
        case 3:
            toolTip = "Neschváleno"
            return <><AiFillDislike style={{color: "red"}} size={25} data-tip={toolTip}/><ReactTooltip html={true}/> </>
        default:
            return <></>;
    }
}
