import React, { useEffect, useState } from "react"
import { apiAuthorizate } from "./Actions";
import { useDispatch } from "react-redux";
import { USER_FETCH_SUCCESS, USER_RESET } from "../../reducers/user";
import { useLocation, useNavigate } from "react-router-dom";
import {DefaultLoader} from "../../components/DefaultLoader";
import {instance} from "../../config/axios-cnf";


const Auth = () => {

    let dispatch = useDispatch()
    let [done, setDone] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const getRoute = (data) => {
        switch(data.user_type){
            case 0:
            case 1:
            case 2:
            case 3:
                if (location.pathname === "/" || location.pathname === "/login") {
                    navigate("/account/exhibit");
                }
                break;
            default:
                localStorage.removeItem('authorization-leg-enigoo')
                instance.defaults.headers.common["Authorization"] = null;
                dispatch({ type: USER_RESET });
                break;
        }

    }

    useEffect(() => {
        authorizate()
    }, [])

    const authorizate = () => {
        apiAuthorizate(data => {
            dispatch({ type: USER_FETCH_SUCCESS, payload: data });
            setDone(true)
            getRoute(data)
        }, () => {
            localStorage.removeItem('authorization-leg-enigoo')
            instance.defaults.headers.common["Authorization"] = null;
            dispatch({ type: USER_RESET })
            setDone(true)
        })
    }

    //return <DefaultLoader isDone={done} />

    return <DefaultLoader text={"Načítám stránku..."}/>
}
export default Auth
