import React from "react";
import {useEffect, useState} from "react";
import {apiGetExponat} from "./Actions";
import {Button} from "../../components/My/Button";
import EnigooBreadcrumbs from "../../components/breadcrumbs/EnigooBreadcrumbs";
import Widget from "../../components/widget";
import ExponatInfo from "./ExponatInfo";
import {FiPlus} from "react-icons/fi";
import {DefaultLoader} from "../../components/DefaultLoader";
import {shallowEqual, useSelector} from "react-redux";
import {FiPrinter} from "react-icons/all";
import Toast from "cogo-toast";
import ActionDropDown from "../../components/FinalForm/ActionDropDown";

const Exponat = (props) => {

    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    const {actualAction} = useSelector(
        state => (state.actualAction),
        shallowEqual
    )

    useEffect(() => {
        setLoading(true);
        apiGetExponat((data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            setLoading(false);
        })
    }, [])

    const _renderButton = () => (
        <Button text={"Nový exponát"} link={"/account/exhibit/add"} icon={<FiPlus/>}/>
    )


    return (
        <>
            <EnigooBreadcrumbs right={_renderButton()}/>
            {loading && <DefaultLoader text={"Načítám exponáty..."}/>}
            {data && data.map((item, index) => {
                return (
                    <Widget key={index}>
                        <ExponatInfo exponat={item}/>
                    </Widget>
                )
            })}

        </>
    )
}
export default Exponat;
