import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {apiPostCreateExponat, apiPostNewExponatPhoto} from "../Actions";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../../components/DefaultLoader";
import ExponatFormInfo from "./ExponatFormInfo";
import UserInfoForm from "./UserInfoForm";
import ActionDetailForm from "./ActionDetailForm";
import {Button} from "../../../components/My/Button";
import {FiSave} from "react-icons/all";
import {Form} from "react-final-form";
import EnigooBreadcrumbs from "../../../components/breadcrumbs/EnigooBreadcrumbs";
import {apiGetActualUserInfo} from "../../User/Actions";
import ImagesNewExponatForm from "./ImagesNewExponatForm";

const NewExponatForm = () => {
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [data, setData] = useState(null);
    let [photo, setPhoto] = useState([]);

    const navigate = useNavigate();
    let submit = null;
    let submitError = false;

    useEffect(() => {
        getData();
    }, [])

    const onSubmit = (values) => {

        if (photo.length > 0) {
            setSaving(true);
            let array = [];
            photo.forEach((item, index) => {
                array.push({
                    "id": item.id,
                    "name": item.name
                })
            })
            values.photo = array;
            apiPostCreateExponat({data: values}, (data) => {
                Toast.success("V pořádku uloženo.");
                setSaving(false);
                navigate("/account/exhibit/detail/" + data.id);
            }, (err) => {
                Toast.error("Nastala chyba při vytváření exponátu.")
                setSaving(false);
            })
        }else{
            Toast.error("Nahrajte prosím alespoň jednu fotografii.")
        }
    }

    const getData = () => {
        //TODO: ziskat data userAction (pokud jsou)
        setLoading(true);
        const onSuccess = (data) => {
            setData(data);
            setPhoto(data.user.photo)
            setLoading(false);
        };
        const onError = (error) => {
            Toast.error("Nepodařilo se načíst data exponátu.");
            setLoading(false);
        }

        apiGetActualUserInfo(onSuccess, onError);
    }

    const _renderBody = (values, handleSubmit) => {
        if (loading) return <DefaultLoader text={"Načítám data exponátu..."}/>;
        else {
            return (
                <>
                    {submitError && (data && data.detail && data.detail.state !== 1) || window.location.pathname.includes("/account/exhibit/add") ?
                        <div
                            className={`flex flex-col bg-red-100 border border-l-4 border-red-500 rounded text-red-500 p-8 pb-8 w-full mb-2 text-base`}>
                            Vyplňte prosím všechna povinná pole.
                        </div> : <></>
                    }
                    <ExponatFormInfo data={data}/>
                    <ImagesNewExponatForm data={photo} setData={setPhoto} updateImage={apiPostNewExponatPhoto}/>
                    <UserInfoForm/>
                    <ActionDetailForm values={values}/>
                    <div
                        className={`flex flex-col bg-white border border-grey-200 lg:p-8 p-2 pb-8 w-full mb-2 text-base`}>
                        <Button icon={<FiSave/>} loading={saving} text={"Uložit"} onClick={handleSubmit}/>
                        {submitError ?
                            <span className={"text-red-500"}>Vyplňte prosím všechna povinná pole.</span> : <></>}
                    </div>
                </>
            )
        }
    }


    const _renderButtons = (handleSubmit) => {
        return <Button icon={<FiSave/>} loading={saving} text={"Uložit"} onClick={handleSubmit}/>
    }

    return (
        <Form onSubmit={onSubmit} initialValues={data}
              render={({handleSubmit, values, hasSubmitErrors, hasValidationErrors}) => {
                  submitError = hasSubmitErrors || hasValidationErrors;
                  submit = handleSubmit;
                  return (<>

                          <EnigooBreadcrumbs right={_renderButtons(handleSubmit)}/>
                          {_renderBody(values, handleSubmit)}
                      </>
                  )
              }}
        />
    )


}
export default NewExponatForm;
