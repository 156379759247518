import React from "react";
import {Link} from "react-router-dom";


export const Button = ({text, onClick, loading, textSize, link = null, icon, extraStyle = "",disabled=false}) => {


    let button =
        <button
            className={"btn btn-default rounded btn-icon bg-legendyColor hover:bg-legendyColor space-x-1 mt-1 text-white btn-rounded " + extraStyle}
            onClick={onClick} disabled={disabled||loading}>
            {icon}
            <span className={textSize ? textSize : "text-sm"}>{loading ? "Pracuji..." : text}</span>

        </button>;


    if (link) return <Link to={link}>{button}</Link>
    else return button;
}
