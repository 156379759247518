import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {FiX} from "react-icons/fi";
import {FiSave} from "react-icons/all";
import {
    apiPostChangeStateAdminExponat,
} from "./Actions";
import {Form} from "react-final-form";
import Portal from "../../components/portal";
import {Button} from "../../components/My/Button";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../components/DefaultLoader";
import SelectField from "../../components/FinalForm/SelectField";
import {useParams} from "react-router-dom";
import actualAction from "../../reducers/actualAction";

export const ChangeExponatStateModal = ({
                                            setOpen,
                                            open,
                                            setData,
                                            saving, setSaving
                                        }) => {
    let [loading, setLoading] = useState(false);

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.palettes,
        }),
        shallowEqual
    )
    const {actualAction} = useSelector(
        state => (state.actualAction),
        shallowEqual
    )
    let {background} = {
        ...palettes
    }

    const {id} = useParams();

    const modalRef = useRef(null)

    const hide = () => {
        setOpen(false)
    }


    const send = (values) => {
        setSaving(true);
        values.carId = id;
        apiPostChangeStateAdminExponat({...values,actionId:actualAction.id},(data)=>{
            Toast.success("Stav změnen.");
            let image = [];
            data.image.map((item,index)=>{
                image.push({src:item.data,title:item.name})
            })
            data.image = image;
            setData(data);
            setSaving(false);
            hide();
        },(error)=>{
            Toast.error("Nepodařilo se změnit stav.");
            setSaving(false);
        })
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open)
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }

    }, [open, modalRef])

    const options = [
        {label: "Schváleno", value: 1},
        {label: "Je třeba doplnit údaje", value: 2},
        {label: "Neschváleno", value: 3}
    ]

    return (
        <>
            {open && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                         data-background={background}>
                        <div className="relative min-w-sm w-1/3 mx-auto lg:max-w-5xl bg-white rounded" ref={modalRef}>
                            <div className="modal-content">
                                {loading ? <DefaultLoader text={"Načítám data..."}/> : <>
                                    <div className="modal-header">
                                        <h3 className="text-xl font-semibold">{"Změna stavu exponátu"}</h3>
                                        <button
                                            className="modal-close btn btn-transparent"
                                            onClick={hide}>
                                            <FiX size={18} className="stroke-current"/>
                                        </button>
                                    </div>
                                    <Form onSubmit={send} render={({handleSubmit}) => (
                                        <>
                                            <div className={"m-2"}>
                                                <SelectField name={"state"} options={options} label={"Nový stav:"} isMandatory={true}/>
                                            </div>
                                            <div className="modal-footer space-x-2">
                                                <Button disabled={saving} loading={saving} onClick={hide}
                                                        text={"Zrušit"}
                                                        icon={<FiX/>}/>
                                                <Button disabled={saving} loading={saving} onClick={handleSubmit}
                                                        text={"Uložit"} icon={<FiSave/>}/>
                                            </div>
                                        </>
                                    )}/>


                                </>}
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}
