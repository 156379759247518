import {instance, onErrorFunction} from "../../config/axios-cnf";

export const apiGetAdminExponat = (actionId,callback, error) => {
    instance.get("/api/v1/exponat/"+actionId)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetAdminExponatById = (actionId,id, callback, error) => {
    instance.get("/api/v1/exponat/"+actionId+"/" + id)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPutAdminExponat = (actionId,id, data, callback, error) => {
    instance.put("/api/v1/exponat/"+actionId+"/" + id, data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPostAssignExponatToSection = (data, callback, error) => {
    instance.post("api/v1/exponat/section", data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPostFilterAdminExponat = (actionId,data, callback, error) => {
    instance.post("api/v1/exponat/filter/"+actionId, data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPostPrintAdminExponat = (data, callback, error) => {
    instance.post("api/v1/exponat/print", data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const    apiPostChangeStateAdminExponat = (data, callback, error) => {
    instance.post("api/v1/exponat/change-state", data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPutUploadPhotoAdmin = (id, data, callback,error)=>{
    instance.put("/api/v1/exponat/image/"+id, data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
