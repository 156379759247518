import React, {useState} from 'react'
import PropTypes from 'prop-types'
import '../../css/components/tabs.css'
import {AiFillCar} from "react-icons/all";

export const VerticalTabs = ({tabs}) => {
    const [openTab, setOpenTab] = useState(0)
    return (
        <div className="flex flex-row items-start justify-start tabs">
            <div className="flex-shrink-0">
                <div className="flex flex-wrap flex-col space-y-2">
                    {tabs.map((tab, key) => (
                        <button
                            key={key}
                            onClick={() => {
                                setOpenTab(tab.index)
                            }}
                            className={`tab tab-pill ${
                                openTab === tab.index ? 'tab-active' : ''
                            }`}
                            type="button">
                            {tab.title}
                        </button>
                    ))}
                </div>
            </div>
            <div className="ml-0">
                {tabs.map((tab, key) => (
                    <div
                        className={`tab-content ${
                            openTab !== tab.index ? 'hidden' : 'block'
                        }`}>
                        {tab.content}
                    </div>
                ))}
            </div>
        </div>
    )
}

VerticalTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number,
            content: PropTypes.element,
            title: PropTypes.any
        })
    ).isRequired
}

export const Pills = ({tabs}) => {
    const [openTab, setOpenTab] = useState(0)
    return (
        <div className="flex flex-wrap flex-col w-full tabs">
            <div className="flex lg:flex-wrap flex-row lg:space-x-2">
                {tabs.map((tab, key) => (
                    <div key={key} className="flex-none">
                        <button
                            onClick={() => {
                                setOpenTab(tab.index)
                            }}
                            className={`tab tab-pill ${
                                openTab === tab.index ? 'tab-active' : ''
                            }`}
                            type="button">
                            {tab.title}
                        </button>
                    </div>
                ))}
            </div>
            {tabs.map((tab, key) => (
                <div
                    className={`tab-content ${
                        openTab !== tab.index ? 'hidden' : 'block'
                    }`}>
                    {tab.content}
                </div>
            ))}
        </div>
    )
}

Pills.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number,
            content: PropTypes.element,
            title: PropTypes.any
        })
    ).isRequired
}

export const IconTabs = ({tabs}) => {
    const [openTab, setOpenTab] = useState(0)
    return (
        <div>
            <div className={`lg:w-1/2 w-full grid lg:grid-cols-${tabs.length} grid-cols-2 place-content-stretch gap-2 mb-2`}>
                {tabs.map((tab, key) => (
                    <div key={key} className="w-full">
                        <button
                            onClick={() => {
                                setOpenTab(tab.index)
                            }}
                            className={`w-full btn btn-default border-2 btn-rounded border-grey-900 hover:bg-legendyColor hover:text-white space-x-1 mt-1 mr ${
                                openTab === tab.index ? 'bg-legendyColor text-white' : 'bg-white text-grey-900'
                            }`}
                            type="button">
                            <span className={"text-xl"}>{tab.title}</span>
                        </button>
                    </div>
                ))}
            </div>

            {tabs.map((tab, key) => (
                <div key={key}
                    className={` ${
                        openTab !== tab.index ? 'hidden' : 'block'
                    }`}>
                    {tab.content}
                </div>
            ))}
        </div>
    )
}

IconTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number,
            content: PropTypes.element,
            title: PropTypes.any
        })
    ).isRequired
}

export const UnderlinedTabs = ({tabs}) => {
    const [openTab, setOpenTab] = useState(0)
    return (
        <div className="flex flex-wrap flex-col w-full tabs">
            <div className="flex lg:flex-wrap flex-row lg:space-x-2">
                {tabs.map((tab, key) => (
                    <div key={key} className="flex-none">
                        <button
                            onClick={() => {
                                setOpenTab(tab.index)
                            }}
                            className={
                                openTab === tab.index
                                    ? 'tab tab-underline tab-active'
                                    : 'tab tab-underline'
                            }
                            type="button">
                            {tab.title}
                        </button>
                    </div>
                ))}
            </div>
            {tabs.map((tab, key) => (
                <div
                    key={key}
                    className={`tab-content ${
                        openTab !== tab.index ? 'hidden' : 'block'
                    }`}>
                    {tab.content}
                </div>
            ))}
        </div>
    )
}

UnderlinedTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number,
            content: PropTypes.element,
            title: PropTypes.any
        })
    ).isRequired
}

export const DefaultTabs = ({tabs}) => {
    const [openTab, setOpenTab] = useState(0)
    return (
        <div className="flex flex-wrap flex-col w-full tabs">
            <div className="flex lg:flex-wrap flex-row lg:space-x-2">
                {tabs.map((tab, key) => (
                    <div key={key} className="flex-none">
                        <button
                            onClick={() => {
                                setOpenTab(tab.index)
                            }}
                            className={`tab ${openTab === tab.index ? 'tab-active' : ''}`}
                            type="button">
                            {tab.title}
                        </button>
                    </div>
                ))}
            </div>
            {tabs.map((tab, key) => (
                <div
                    className={`tab-content ${
                        openTab !== tab.index ? 'hidden' : 'block'
                    }`}>
                    {tab.content}
                </div>
            ))}
        </div>
    )
}

DefaultTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number,
            content: PropTypes.element,
            title: PropTypes.any
        })
    ).isRequired
}
