import React, {useEffect, useState} from "react";
import RadioField from "../../../components/FinalForm/RadioField";
import SelectField from "../../../components/FinalForm/SelectField";
import InputField from "../../../components/FinalForm/InputField";
import CheckboxField from "../../../components/FinalForm/CheckboxField";
import {shallowEqual, useSelector} from "react-redux";

const ActionDetailForm = ({values, title = "DETAIL K AKCI", part = 4, errors, submitFailed, disabled = false}) => {
    let [eatOptions, setEatOptions] = useState(null);

    const {actualAction} = useSelector(
        state => (state.actualAction),
        shallowEqual
    )

    const sizes = [
        {value: "S", label: "S"},
        {value: "M", label: "M"},
        {value: "L", label: "L"},
        {value: "XL", label: "XL"},
        {value: "2XL", label: "2XL"},
        {value: "3XL", label: "3XL"}
    ]

    const drinkOptions = [
        {value: "voda", label: "Voda"},
        {value: "limo", label: "Limo"},
        {value: "kava", label: "Káva"},
        {value: "nealko", label: "Nealko pivo"},
        {value: "pivo", label: "Pivo"},
        {value: "vino", label: "Víno"}
    ]
    useEffect(() => {
        if (actualAction && actualAction.food && actualAction.food.food) {
            setEatOptions(actualAction.food.food);
        }
    }, [actualAction]);

    return (
        <div className={`flex flex-col bg-white border border-grey-200 lg:p-8 p-2 pb-8 w-full mb-2 text-base`}>
            <div className="flex flex-row w-full mb-6">
                <h4 className="font-bold"><span className={"text-legendyColor"}>ČÁST {part}</span> - {title}</h4>
            </div>

            <div className={"w-full"}>
                {/*<div className={"grid lg:grid-cols-2 grid-cols-1 lg:gap-2 place-content-start"}>
                    <div className={"form-element lg:mt-1"}>
                        <div className="form-label font-bold">
                            Velikost trička - řidič: <sup className={"text-legendyColor"}>*</sup>
                        </div>
                        <div className="lg:w-1/2 w-full flex flex-row">
                            <div className={"flex flex-col"}>
                                <RadioField disabled={disabled} name={"user.detail.driver.sex"} label={"Žena"} id={"s-d" + 0}
                                            value={"Žena"} defaultChecked={values && values?.user?.detail?.driver?.sex === "Žena"}
                                            group={"driver.sex"} isMandatory={true}/>
                            </div>
                            <div className={"flex flex-col"}>
                                <RadioField disabled={disabled} name={"user.detail.driver.sex"} label={"Muž"} id={"s-d" + 1}
                                            value={"Muž"} defaultChecked={values && values?.user?.detail?.driver?.sex === "Muž"}
                                            group={"driver.sex"} isMandatory={true}/>
                            </div>
                        </div>
                    </div>

                    <SelectField disabled={disabled} name={"user.detail.driver.sizeShirt"} options={sizes} label={"Velikost trička"}
                                 isMandatory={true}/>

                </div>*/}
                <div className={"grid lg:grid-cols-2 grid-cols-1 justify-center"}>
                    <div className={"form-element lg:mt-1"}>
                        <div className="form-label font-bold">
                            Jsem aktivním členem klubu: <sup className={"text-legendyColor"}>*</sup>
                        </div>

                        <div className="lg:w-1/2 w-full flex flex-row">
                            <div className={"flex flex-col"}>
                                <RadioField disabled={disabled} name={"user.detail.driver.inClub"} label={"Ano"}
                                            id={"inc-d" + 0}
                                            value={1}
                                            defaultChecked={values && values?.user?.detail?.driver?.inClub === 1}
                                            group={"driver.inClub"} isMandatory={true}/>
                            </div>
                            <div className={"flex flex-col"}>
                                <RadioField disabled={disabled} name={"user.detail.driver.inClub"} label={"Ne"}
                                            id={"inc-d" + 1}
                                            value={0}
                                            defaultChecked={values && values?.user?.detail?.driver?.inClub === 0}
                                            group={"driver.inClub"} isMandatory={true}/>
                            </div>
                        </div>
                    </div>

                    <InputField disabled={disabled} name={"user.detail.driver.club"} label={"Název klubu"}
                                isMandatory={values?.user?.detail?.driver?.inClub === 1} maxLength={300}/>
                </div>
                <div className="w-full">
                    <InputField disabled={disabled} name={"user.detail.driver.onLegend"}
                                label={"Již jsem se účastnil slavnosti LEGENDY (Počet)"}
                                inputType={"number"} isMandatory={true} maxLength={11}/>
                </div>
                <div className={"w-full flex lg:flex-row flex-col items-stretch"}>
                    <div className={"flex flex-col mb-1 lg:w-1/2 w-full"}>
                        <div className={"ml-1 w-full font-bold"}>Budu na LEGENDÁCH:</div>
                        <div className={"ml-2 flex-col text-sm"}>(Aktivně se budu účastnit celý den)</div>
                    </div>
                    <div className="ml-1 lg:w-1/2 w-full flex flex-col">
                        <div className={"flex flex-row"}>
                            <div className={"flex-col mr-3"}>
                                <CheckboxField disabled={disabled} isMandatory={false}
                                               name={"user.detail.driver.onLegendDays"}
                                               label={"Pátek"} value={"P"}/>
                            </div>
                            <div className={"flex-col mr-3"}>
                                <CheckboxField disabled={disabled} isMandatory={false}
                                               name={"user.detail.driver.onLegendDays"}
                                               label={"Sobota"}
                                               value={"S"}/>
                            </div>
                            <div className={"flex-col mr-3"}>
                                <CheckboxField disabled={disabled} isMandatory={false}
                                               name={"user.detail.driver.onLegendDays"}
                                               label={"Neděle"}
                                               value={"N"}/>
                            </div>
                        </div>
                        <div className={"flex"}>
                            {submitFailed && errors.detail?.driver?.onLegendDays ?
                                <div className={"w-full text-sm text-red-500"}>Vyberte alespoň jednu
                                    možnost</div> : ""}
                        </div>
                    </div>
                </div>
                {/*<div className={"w-full flex lg:flex-row flex-col items-stretch mt-2"}>
                    <div className={"flex flex-col mb-1 lg:w-1/2 w-full"}>
                        <div className={"ml-1 w-full font-bold"}>Chci jít na večerní koncert:</div>
                        <div className={"ml-2 flex-col text-sm"}>(Blues, Jazz, Classic, Pop)</div>
                    </div>
                    <div className="ml-1 lg:w-1/2 w-full flex flex-col">
                        <div className={"flex flex-row"}>
                            <div className={"flex-col mr-3"}>
                                <CheckboxField disabled={disabled} isMandatory={false} name={"user.detail.driver.concert"}
                                               label={"Pátek"}
                                               value={"P"}/>
                            </div>
                            <div className={"flex-col mr-3"}>
                                <CheckboxField disabled={disabled} isMandatory={false} name={"user.detail.driver.concert"}
                                               label={"Sobota"}
                                               value={"S"}/>
                            </div>
                        </div>
                        <div className={"flex"}>
                            {submitFailed && errors.detail?.driver?.concert ?
                                <div className={"w-full text-sm text-red-500"}>Vyberte alespoň jednu
                                    možnost</div> : ""}
                        </div>
                    </div>
                </div>*/}
                <div className={"ml-1 lg:w-full font-bold mt-2"}>Budu pít v Drivers Clubu:</div>
                <div className={"grid lg:grid-cols-7 grid-cols-3 gap-2 ml-1 my-1 pb-8"}>
                    {drinkOptions.map((item, index) => (
                        <div className={"flex-col mr-3"} key={index}>
                            <CheckboxField disabled={disabled} isMandatory={false} name={"user.detail.driver.drink"}
                                           label={item.label}
                                           value={item.value} key={index}/>
                        </div>
                    ))}
                </div>


                <div className={"ml-1 lg:w-1/3 font-bold mt-2"}>Budu jíst v Drivers Clubu:</div>
                {eatOptions != null &&
                    <div className={"grid lg:grid-cols-3 grid-cols-1 gap-3 ml-1 my-1 pb-8"}>
                        {eatOptions.map((item, index) => (
                            <div className={"flex flex-col"} key={index}>
                                <div className={"font-light"}>{item.label}</div>
                                {item.options.map((value, j) => (
                                    <div className={"flex flex-row"} key={j}>
                                        <SelectField disabled={disabled}
                                                     name={`user.detail.driver.eat.${item.day}.${value.name}`}
                                                     options={value.values} label={value.label}/>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>}

                <div className={"w-full flex lg:flex-row flex-col"}>
                    <div className="lg:w-1/2 w-full font-bold">Chci se zúčastnit Soutěže krásy - GRAND PRIX
                        LEGENDY:
                    </div>
                    <div className="lg:w-1/2 w-full flex flex-row">
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"user.detail.driver.miss"} label={"ANO"}
                                        id={"miss-d" + 0} value={1}
                                        group={"driver.miss"}
                                        defaultChecked={values && values?.user?.detail?.driver?.miss === 1}
                                        isMandatory={true}/>
                        </div>
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"user.detail.driver.miss"}
                                        defaultChecked={values && values?.user?.detail?.driver?.miss === 0} label={"NE"}
                                        id={"miss-d" + 1} value={0}
                                        group={"driver.miss"} isMandatory={true}/>
                        </div>
                    </div>
                </div>

                {/*<div className={"w-full flex lg:flex-row flex-col"}>
                    <div className="lg:w-1/2 w-full font-bold">Chci se zúčastnit spanilé vyjížďky Prahou:</div>
                    <div className="lg:w-1/2 w-full flex flex-row">
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"user.detail.driver.racePrague"} label={"ANO"} id={"race-d" + 0}
                                        value={1} defaultChecked={values && values?.user?.detail?.driver?.racePrague === 1}
                                        group={"driver.racePrague"} isMandatory={true}/>
                        </div>
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"user.detail.driver.racePrague"} label={"NE"} id={"race-d" + 1}
                                        value={0}  defaultChecked={values && values?.user?.detail?.driver?.racePrague === 0}
                                        group={"driver.racePrague"} isMandatory={true}/>
                        </div>
                    </div>
                </div>*/}

                <div className={"w-full flex lg:flex-row flex-col"}>
                    <div className="lg:w-1/2 w-full font-bold">Budu aktivně prezentovat svůj vůz</div>
                    <div className="lg:w-1/2 w-full flex flex-row">
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"user.detail.driver.present"} label={"ANO"}
                                        id={"present-d" + 0}
                                        value={1} defaultChecked={values && values?.user?.detail?.driver?.present === 1}
                                        group={"driver.present"} isMandatory={true}/>
                        </div>
                        <div className={"flex-col"}>
                            <RadioField disabled={disabled} name={"user.detail.driver.present"} label={"NE"}
                                        id={"present-d" + 1}
                                        value={0} defaultChecked={values && values?.user?.detail?.driver?.present === 0}
                                        group={"driver.present"} isMandatory={true}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"w-full flex lg:flex-row flex-col"}>
                <div className="lg:w-1/2 w-full font-bold">Využiji druhý vstup: <sup
                    className={"text-legendyColor"}>*</sup></div>
                <div className="lg:w-1/2 w-full flex flex-row">
                    <div className={"flex-col"}>
                        <RadioField disabled={disabled} name={"user.detail.secondDriver.is"} label={"ANO"}
                                    id={"sd-sd" + 0} value={1}
                                    group={"secondDriver.is"}
                                    defaultChecked={values && values?.user?.detail?.secondDriver?.is === 1}
                                    isMandatory={true}/>
                    </div>
                    <div className={"flex-col"}>
                        <RadioField disabled={disabled} name={"user.detail.secondDriver.is"} label={"NE"}
                                    id={"sd-sd" + 1} value={0}
                                    group={"secondDriver.is"}
                                    defaultChecked={values && values?.user?.detail?.secondDriver?.is === 0}
                                    isMandatory={true}/>
                    </div>
                </div>
            </div>

            {values?.user?.detail?.secondDriver?.is === 1 &&
                <>
                    {/*<div className={"grid lg:grid-cols-2 grid-cols-1 lg:gap-2 place-content-start"}>
                        <div className={"form-element lg:mt-1"}>
                            <div className="form-label font-bold">
                                Velikost trička - spolujezdec: *
                            </div>
                            <div className="lg:w-1/2 w-full flex flex-row">
                                <div className={"flex flex-col"}>
                                    <RadioField disabled={disabled} name={"user.detail.secondDriver.sex"} label={"Žena"}
                                                id={"s-sd" + 0}
                                                value={"Žena"} defaultChecked={values && values?.user?.detail?.secondDriver?.sex === "Žena"}
                                                group={"secondDriver.sex"} isMandatory={true}/>
                                </div>
                                <div className={"flex flex-col"}>
                                    <RadioField disabled={disabled} name={"user.detail.secondDriver.sex"} label={"Muž"}
                                                id={"s-sd" + 1}
                                                value={"Muž"} defaultChecked={values && values?.user?.detail?.secondDriver?.sex === "Muž"}
                                                group={"secondDriver.sex"} isMandatory={true}/>
                                </div>
                            </div>
                        </div>

                        <SelectField disabled={disabled} name={"user.detail.secondDriver.sizeShirt"} options={sizes}
                                     label={"Velikost trička"}
                                     isMandatory={true}/>

                    </div>*/}
                    <div className={"ml-1 lg:w-1/3 font-bold"}>Spolujezdec bude pít v Drivers Clubu: <sup
                        className={"text-legendyColor"}>*</sup></div>
                    <div className={"grid lg:grid-cols-7 grid-cols-1 gap-2 ml-1 my-1 pb-8"}>
                        {drinkOptions.map((item, index) => (
                            <CheckboxField disabled={disabled} isMandatory={false}
                                           name={"user.detail.secondDriver.drink"}
                                           label={item.label} value={item.value} key={index}/>
                        ))}
                    </div>


                    <div className={"ml-1 lg:w-1/3 font-bold"}>Spolujezdec bude jíst v Drivers Clubu: <sup
                        className={"text-legendyColor"}>*</sup></div>
                    <div className={"grid lg:grid-cols-3 grid-cols-1 gap-3 ml-1 my-1 pb-8"}>
                        {eatOptions != null && eatOptions.map((item, index) => (
                            <div className={"flex flex-col"} key={index}>
                                <div className={"font-light"}>{item.label}</div>
                                {item.options.map((value, j) => (
                                    <div className={"flex flex-row"} key={j}>
                                        <SelectField disabled={disabled}
                                                     name={`user.detail.secondDriver.eat.${item.day}.${value.name}`}
                                                     options={value.values} label={value.label}/>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </>
            }
            <div className="w-full"><h6 className={"font-bold mb-2 mt-4"}>Speciální žádost</h6></div>
            <div className={"w-full flex flex-col ml-1"}>
                <div className="w-full flex-col">
                    <div className={"font-bold flex-col"}>Chci se účastnit, ale chci exponátem večer odjet
                    </div>
                    <div className={"flex-col text-sm"}>Pravidla udělení výjimky:<br/>
                        Přijedu v pátek, sobotu, neděli, vždy 1 hodinu před otevřením pro veřejnost a umístím vůz na
                        stanovené místo.<br/>
                        Vůz bude vybaven označením a popiskou.
                    </div>
                    <div className={"flex-col text-sm"}>
                        (Tato speciální výjimka bude udělena pouze maximálně 25 účastníkům. Děkujeme za pochopení.)
                    </div>
                </div>
                <div className="w-full flex flex-row">
                    <div className={"flex-col"}>
                        <RadioField disabled={disabled} name={"user.detail.driver.leave"} label={"ANO"}
                                    id={"leave-d" + 0} value={1}
                                    group={"driver.leave"}
                                    defaultChecked={values && values?.user?.detail?.driver?.leave === 1}
                                    isMandatory={true}/>
                    </div>
                    <div className={"flex-col"}>
                        <RadioField disabled={disabled} name={"user.detail.driver.leave"} label={"NE"}
                                    id={"leave-d" + 1} value={0}
                                    defaultChecked={values && values?.user?.detail?.driver?.leave === 0}
                                    group={"driver.leave"} isMandatory={true}/>
                    </div>
                </div>
            </div>
            <div className="lg:w-1/3 w-full"><h6 className={"font-bold mb-2 mt-4"}>Doplňující informace</h6>
            </div>

            <div className="lg:w-full w-full">
                <InputField disabled={disabled} name={"detail.user.driver.otherAction"}
                            label={"Aktivně se účastním i těchto dalších akcí"}
                            isMandatory={false} maxLength={400}/>
                <InputField disabled={disabled} name={"detail.user.driver.recomend"}
                            label={"Jaké jiné akce bych organizátorovi doporučil"}
                            isMandatory={false} inputType={"textarea"} maxLength={400}/>
                <InputField disabled={disabled} name={"detail.user.driver.recomendToAction"}
                            label={"Doporučil bych organizátorovi akce (připomínky)"}
                            isMandatory={false} inputType={"textarea"} maxLength={400}/>
            </div>

        </div>
    )
}
export default ActionDetailForm
