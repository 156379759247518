import React, {useEffect, useState} from "react"
import {apiConfirmAccount, apiSendForgotPassword} from "./Actions";
import Layout from "../../layouts/centered";
import CenteredForm from "../../layouts/centered-form";
import Alert from "../../components/alerts";
import {Link, useParams} from "react-router-dom";
import {Button} from "../../components/My/Button";
import * as Sentry from "@sentry/react";

const ConfirmAccount = () => {


    let [loading, setLoading] = useState(false)
    let [result, setResult] = useState(false);
    let [errorMessage, setErrorMessage] = useState(null);

    let {code} = useParams();

    useEffect(() => {
        confirm(code);
    }, []);

    const confirm = (code) => {
        setLoading(true);
        apiConfirmAccount(code, (data) => {
            setResult(true);
            setLoading(false);
        }, (error) => {
            switch (error?.type) {
                case"USER_VERIFIED_ALREADY":
                    setErrorMessage("Váš učet je již ověřený.")
                    break;
                default:
                    setErrorMessage("Nepodařilo se potvrdit Vaší registraci. Pravděpodobně jste již registraci potvrdil.");
                    break;
            }
            setResult(false);
            setLoading(false);
        })
    }

    const getAlert = () =>{
        if(loading){
            return <Alert color={"text-blue-500"} outlined={true}>Ověřuji Váš učet...</Alert>;
        }
        if(!loading && result){
            return <Alert color={"text-green-500"} outlined={true}>Úspěšně potvrzeno. Nyní se můžete přihlásit do
                Vašeho účtu.</Alert>
        }
        if(!loading && errorMessage){
            return <Alert color={"text-red-500"} outlined={true}>{errorMessage}</Alert>
        }
    }

    return (
        <Layout>
            <div className={"flex flex-col w-full max-w-xl"}>
                <div className={"flex items-center justify-center pb-6"}>
                    <img src={"/assets/logo-legendy.png"} className={"h-48"} alt={"Legendy.cz"}/>
                </div>
                <CenteredForm subtitle="Potvrzení registrace" title={""}>
                    {getAlert()}

                </CenteredForm>
                <div className={"justify-center w-full text-center pt-2 mx-auto grid place-content-center"}>
                    <Button link={"/login"} text={"Přejít k přihlášení"}/>
                    {/*<Link to={"/login"} className={"text-legendyColor text-base"}>Přihlásit se</Link>*/}
                </div>
            </div>
        </Layout>
    )
}

export default ConfirmAccount;
