import {instance, onErrorFunction} from "../../config/axios-cnf";


export const apiRegisterUser = (values, callback, error) => {
    instance.post("/api/v1/user/register", values)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetFood = (callback, error) => {
    instance.get("/api/v1/food")
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPostCheckEmail = (email, callback, error) => {
    instance.post("/api/v1/user/check", {email: email})
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetRegisterToken = (callback,error) =>{
    instance.get("/api/v1/user/register/token")
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetAuthRegisterToken = (callback, error) => {
    instance.get("/api/v1/user/register/auth")
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
export const apiPostUploadPhoto = (data, callback,error)=>{
    instance.post("/api/v1/user/register/image",data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetPhoto = (callback,error)=>{
    instance.get("/api/v1/user/register/image")
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPutDeleteRegistrationImage=(data, callback, error)=>{
    instance.put("/api/v1/user/register/image",data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetRegisterSection = (callback,error)=>{
    instance.get("/api/v1/user/register/section")
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
