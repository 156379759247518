export default function actualAction(
    state = {
        actualAction: {
            id: null,
            name: null,
            isActive: null,
            food: null
        }
    },
    action
) {
    switch (action.type) {
        case "SET_ACTION":
            return {
                ...state, actualAction: {
                    id: action.actualAction.id,
                    name: action.actualAction.name,
                    isActive: action.actualAction.isActive,
                    food: action.actualAction.food
                }
            }
        case "CHANGE_ACTION":
            return {...state, actualAction: action.actualAction}
        case "RESET_ACTUAL_ACTION":
            return {...state, actualAction: null}
        case "RESET":
            return {
                actualAction: {
                    id: null,
                    name: null,
                    isActive: null,
                    food: null
                }
            }
        default:
            return state;
    }
}
