import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {apiDeleteSection, apiGetSectionById, apiPostCreateSection, apiPutUpdateSection} from "./Actions";
import Toast from "cogo-toast";
import EnigooBreadcrumbs from "../../components/breadcrumbs/EnigooBreadcrumbs";
import {Button} from "../../components/My/Button";
import {FiDelete, FiSave} from "react-icons/fi";
import {Form} from "react-final-form";
import InputField from "../../components/FinalForm/InputField";
import {DefaultLoader} from "../../components/DefaultLoader";
import {shallowEqual, useSelector} from "react-redux";
import CogoToast from "cogo-toast";


const SectionForm = () => {

    let [data, setData] = useState();
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [deleting,setDeleting] = useState(false);
    const {id} = useParams();
    const navigation = useNavigate();

    let {userState} = useSelector(
        state =>({
            userState: state.user
        }),
        shallowEqual
    );

    useEffect(() => {
        if (id) {
            setLoading(true);
            apiGetSectionById(id, (data) => {
                setData(data);
                setLoading(false);
            }, (error) => {
                Toast.error("Nepodařilo se načíst data o sekci.")
                navigation("/user/section");
                setLoading(false);
            })
        }
    }, [])

    const onSubmit = (values) => {
        setSaving(true);
        if (id) {
            apiPutUpdateSection(id, values, (data) => {
                setSaving(false);
            }, (error) => {
                Toast.error("Nepodařilo se upravit sekci.")
                setSaving(false);
            })
        } else {
            apiPostCreateSection(values, (data) => {
                navigation("/settings/section/detail/" + data.id);
                setSaving(false);
            }, (error) => {
                Toast.error("Nepodařilo se uložit novou sekci.")
                setSaving(false);
            })
        }
    }

    const deleteSection = () =>{
        setDeleting(true);
        apiDeleteSection(id,(data)=>{
            CogoToast.success("Sekce byla odstraněna.");
            setSaving(false);
            navigation("/settings/section");
        },(error)=>{
            CogoToast.success("Sekci se nepodařilo odstranit.");
            setSaving(false);
        })
    }


    return (
        <div className="w-full flex justify-center bg-grey-50">
            <Form onSubmit={onSubmit} initialValues={data}
                  render={({handleSubmit, values, ...props}) => {
                      return (
                          <div className={"flex flex-col w-full items-center"}>
                              <EnigooBreadcrumbs
                                  right={<div className={"flex flex-row"}>
                                      {id && userState.user.user_type === 1 &&<div className={"mr-2"}>
                                          <Button loading={deleting} text={"Odstranit"} icon={<FiDelete/>} onClick={()=>deleteSection()}/>
                                      </div>}
                                      <Button loading={saving} text={"Uložit"} onClick={() => handleSubmit()}
                                              icon={<FiSave/>}/>
                                  </div>}/>
                              {loading ? <DefaultLoader text={"Načítám data sekce..."}/> : <>
                                  <div
                                      className={`flex flex-col bg-white border border-grey-200 lg:p-8 sm: p-2 w-full sm:max-w-full mb-2 text-base`}>
                                      <div className="form flex flex-wrap w-full">
                                          <div className="lg:w-1/2 sm:w-1/2 w-full">
                                              <InputField name={'name'} label={"Jméno"}
                                                          inputType={"text"}
                                                          placeholder={"Zadejte jméno"} isMandatory={true}/>
                                          </div>
                                      </div>
                                  </div>
                              </>}
                          </div>
                      )
                  }}/>
        </div>
    )

}
export default SectionForm;
