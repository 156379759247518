import React, {useEffect, useState} from "react";
import {apiGetUser} from "./Actions";
import Toast from "cogo-toast";
import {useNavigate} from "react-router-dom";
import EnigooBreadcrumbs from "../../components/breadcrumbs/EnigooBreadcrumbs";
import {FiPlus} from "react-icons/fi";
import {Button} from "../../components/My/Button";
import Datatable from "../../components/datatable";
import UserTable from "./UserTable";
import {DefaultLoader} from "../../components/DefaultLoader";

const User = (props) => {

    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);

    let navigation = useNavigate();

    useEffect(() => {
        setLoading(true);
        apiGetUser((data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            Toast.error("Nepodařilo se načíst uživatele.");
            setLoading(false);
            navigation("/");
        })
    }, [])

    return (
        <>
            <EnigooBreadcrumbs right={<Button text={"Přidat uživatele"} link={"/settings/user/add"} icon={<FiPlus/>}/>}/>
            {loading ? <DefaultLoader text={"Načítám uživatele..."}/> :
                <>
                    <UserTable data={data}/>
                </>}
        </>
    )
}
export default User;