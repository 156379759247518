import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {apiGetAction} from "../../pages/Action/Actions";
import Dropdown from "../dropdowns/dropdown-1";

const ActionDropDown = ({}) => {

    const {actualAction} = useSelector(
        state => (state.actualAction),
        shallowEqual
    )

    const dispatch = useDispatch();

    let [loading, setLoading] = useState(false);
    let [actions, setActions] = useState(null);

    let path = window.location.pathname;
    if (path !== "/account/exhibit/add") {
        path = path.substring(0, path.lastIndexOf("/"))
    }
    let disabled = path === "/settings/exhibit/detail" || path === "/account/exhibit/detail" || path === "/account/exhibit/action" || path === "/account/exhibit/add";

    const queryParam = new URLSearchParams(window.location.search);
    const actionParam = queryParam.get('a');

    useEffect(() => {
        getActions();
    }, []);

    const getActions = () => {
        //console.log(actualAction);
        setLoading(true);
        apiGetAction((data) => {
            setActions(data);
            data.map((item, index) => {
                if(actionParam && parseInt(actionParam)===item.id){
                    dispatch({
                        type: "SET_ACTION",
                        actualAction: item
                    })
                }
                if (item.isActive) {
                    dispatch({
                        type:"SET_ACTIVE_ACTION",
                        activeAction:item
                    })
                    if ((!actualAction || !actualAction.id) || ((path === "/account/exhibit/add"|| path === "/account/exhibit/action") && !actualAction.isActive)) {
                        dispatch({
                            type: "SET_ACTION",
                            actualAction: item
                        })
                    }
                }
            })




            setLoading(false);
        }, (error) => {
            setLoading(false);
        })
    }

    const getActionName = () => {
        let name = "-";
        if (actualAction && actualAction.name && actions) {
            return actualAction.name;
        }
        return name;
    }

    const getCustomField = (id) => {
        let customFields = [];
        actions.map((item, index) => {
            if (item.id === id) {
                customFields = item.customField;
            }
        })
        return customFields;
    }

    if (loading) return <></>;
    if (!actions) return <></>;
    if (actions && actions.length === 0) return <></>;
    if (actions) return (
        <>

            <div className={""}>
                <Dropdown
                    disabled={disabled}
                    width="w-32"
                    placement={`bottom-end`}
                    title={getActionName()}
                    dropdownToggleClass={"w-full btn btn-default border btn-rounded border-legendyColor bg-white text-legendyColor btn-icon hover:bg-legendyColor hover:text-white space-x-1 mt-1 mr"}>
                    <>
                        <div className="flex flex-col text-center">
                            {actions.map((item, i) => (
                                <div
                                    key={i}
                                    className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                                    <button disabled={disabled} onClick={() => {
                                        dispatch({
                                            type: 'CHANGE_PAGE_INDEX',
                                            pageIndex: 0,
                                        })
                                        dispatch({
                                            type: "SET_ACTION",
                                            actualAction: item
                                        });
                                        dispatch({
                                            type: "CHANGE_CUSTOM_FIELD",
                                            customField: getCustomField(item.id)
                                        });

                                    }}
                                            className="btn btn-icon text-xs w-full btn-rounded h-full hover:bg-legendyColor hover:text-white">{item.name}</button>
                                </div>
                            ))}
                        </div>
                    </>
                </Dropdown>
            </div>
        </>
    );
    else return <></>;
}

export default ActionDropDown;
