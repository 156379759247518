import {instance, onErrorFunction} from "../../config/axios-cnf";

export const apiGetExponat = (callback, error) => {
    instance.get("/api/v1/user/exponat")
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetExponatById = (id, callback,error) => {
    instance.get("/api/v1/user/exponat/"+id)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPostCreateExponat = (data, callback, error) => {
    instance.post("/api/v1/user/exponat/new", data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPutExponat = (id,data,callback,error) => {
    instance.put("/api/v1/user/exponat/"+id, data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
export const apiPostExponatNewAction = (data,callback,error)=>{
    instance.post("/api/v1/user/exponat/new-action", data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPutUploadPhoto = (id,data, callback,error)=>{
    instance.put("/api/v1/user/exponat/image/"+id, data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPostDeletePhoto = (data,callback,error) => {
    instance.post("/api/v1/user/exponat/image", data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPostNewExponatPhoto = (data,callback,error)=>{
    instance.put("/api/v1/user/exponat/image", data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}
