

export default function filterCar(
    state = {
        filterCarData:null
    },action
){
    switch (action.type){
        case "SET_FILTER_CAR":
            return{
                ...state,
                filterCarData:action.payload
            }

        case "FILTER_CAR_RESET":
            return {
                ...state, filterCarData:null
            }
        case "RESET":
            return{
                filterCarData: null
            }
        default:
            return state;
    }
}
