import {combineReducers} from 'redux'
import config from './config'
import leftSidebar from './left-sidebar'
import palettes from './palettes'
import user from './user'
import filterCar from './filterCar'
import pages from "./pages";
import actualAction from "./actualAction";
import activeAction from "./activeAction";

const rootReducer = combineReducers({
  config,
  leftSidebar,
  palettes,
  user,
  filterCar,
  pages,
  actualAction,
  activeAction
})

export default rootReducer
