import React, {useEffect, useRef} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {FiLogIn, FiX} from "react-icons/fi";
import Portal from "../../components/portal";
import {Button} from "../../components/My/Button";

export const ModalInvalidEmail = ({
                                      setOpen,
                                      open,
                                      email = ""
                                  }) => {

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.palettes,
        }),
        shallowEqual
    )
    let {background} = {
        ...palettes
    }

    const modalRef = useRef(null)

    const hide = () => {
        setOpen(false)
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open)
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }

    }, [open, modalRef])

    return (
        <>
            {open && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}  border border-black`}
                         data-background={background}>
                        <div className="relative min-w-sm lg:w-1/3 mx-auto lg:max-w-5xl bg-white rounded" ref={modalRef}>
                            <div className="modal-content">

                                <div className="modal-header">
                                    <h3 className="text-xl font-semibold text-red-800">{"Zadaný e-mail je již registrován"}</h3>
                                    <button
                                        className="modal-close btn btn-transparent"
                                        onClick={hide}>
                                        <FiX size={18} className="stroke-current"/>
                                    </button>
                                </div>
                                <div className={"m-2"}>
                                    <p>Email <span className={"font-bold text-red-800"}>{email}</span> je již registrován.</p>
                                    <p>Pokud patří Vám, přihlašte se a ve Vašem účtu přidejte další exponát.</p>
                                </div>
                                <div className="modal-footer space-x-2">
                                    <Button link={"/login"}
                                            text={"Přihlásit se"}
                                            icon={<FiLogIn/>}/>
                                    <Button onClick={hide}
                                            text={"Zavřít"}
                                            icon={<FiX/>}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}
