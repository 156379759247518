import InputField from "../../components/FinalForm/InputField";
import EmailField from "../../components/FinalForm/EmailField";
import React, {useRef, useState} from "react";
import SelectField from "../../components/FinalForm/SelectField";
import {SectionSelectField} from "../Section/SectionSelectField";
import Toast from "cogo-toast";
import {validate} from "email-validator";
import {apiPostCheckEmail} from "./Actions";
import {ModalInvalidEmail} from "./ModalInvalidEmail";

export const PersonalInfo = ({values = null, title, type = "register"}) => {

    const timer = useRef(null)
    let [showModalInvalidMail, setShowModalInvalidMail] = useState(false)
    let [email, setEmail] = useState(null);
    let [mailValid, setMailValid] = useState(null);
    let error = null;
    const checkEmail = (email) => {
        if (type === "register" && validate(email)) {
            setEmail(email);
            apiPostCheckEmail(email, (data) => {

                if (data.valid) {
                    setMailValid(true)
                    setShowModalInvalidMail(false);
                } else {
                    setMailValid(false)
                    setShowModalInvalidMail(true);
                    error = data.message;
                }
            }, (error) => {
                setMailValid(false);
                setShowModalInvalidMail(true);
                error = "UNKNOWN_ERROR";
            })

        }

    }

    const onChange = (name, value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            checkEmail(value);
        }, 2000)
    }

    const userType = [
        {value: 1, label: "Ředitel"},
        {value: 2, label: "Koordinátor"},
        {value: 3, label: "Sekční vedoucí"}
    ];

    return (
        <div
            className={`flex flex-col bg-white border border-grey-200 lg:p-8 sm: p-2 w-full ${type === "register" ? 'lg:max-w-5xl' : ''} sm:max-w-full mb-2 text-base`}>
            <ModalInvalidEmail email={email} open={showModalInvalidMail} setOpen={setShowModalInvalidMail}/>
            <div className="flex flex-row w-full mb-6">
                {title}
                <div className={"ml-auto"}><span className={"text-legendyColor text-xl"}>*</span> Povinné údaje</div>
            </div>
            <div className="form flex flex-wrap w-full">
                <div className="lg:w-1/2 sm:w-1/2 w-full">
                    <InputField name={`${type === "register" ? 'account.firstname' : 'firstname'}`} label={"Jméno"}
                                inputType={"text"}
                                placeholder={"Zadejte příjmení"} isMandatory={true} maxLength={200}/>
                </div>
                <div className="lg:w-1/2 sm:w-1/2 w-full">
                    <InputField name={`${type === "register" ? 'account.surname' : 'surname'}`} label={"Příjmení"}
                                inputType={"text"}
                                placeholder={"Zadejte jméno"} isMandatory={true} maxLength={200}/>
                </div>
                {type === "register" && <>
                    <div className="lg:w-1/2 sm:w-1/2 w-full">
                        <InputField name={"account.age"} label={"Věk"} inputType={"number"}
                                    placeholder={"Zadejte věk"} isMandatory={true} maxLength={3}/>
                    </div>
                    <div className="lg:w-1/2 sm:w-1/2 w-full">
                        <InputField name={"account.phone"} label={"Telefon"} inputType={"text"}
                                    placeholder={"Zadejte telefon"} isMandatory={true} maxLength={30}/>
                    </div>
                    <div className="lg:w-1/2 sm:w-1/2 w-full">
                        <InputField name={"account.street"} label={"Ulice, číslo popisné"} inputType={"text"}
                                    placeholder={"Zadejte ulici"} isMandatory={true} maxLength={250}/>
                    </div>
                </>}
                <div className="lg:w-1/2 sm:w-1/2 w-full">
                    <EmailField name={`${type === "register" ? 'account.email' : 'email'}`}
                                label={"E-mail"}
                                placeholder={"Zadejte email"} onChange={onChange} disabled={(type === "update")}
                                isMandatory={true} maxLength={200}/>
                </div>
                {type === "register" && <>
                    <div className="lg:w-1/2 sm:w-1/2 w-full">
                        <InputField name={"account.city"} label={"Město"} inputType={"text"}
                                    placeholder={"Zadejte město"} isMandatory={true} maxLength={250}/>
                    </div>
                    <div className="lg:w-1/2 sm:w-1/2 w-full">
                        <InputField name={"account.psc"} label={"PSČ"} inputType={"text"}
                                    placeholder={"Zadejte PSČ"} isMandatory={true} maxLength={250}/>
                    </div>
                </>}
                <div className="lg:w-1/2 sm:w-1/2 w-full">
                    <InputField name={`${type === "register" ? 'account.password' : 'password'}`} label={"Heslo"}
                                inputType={"password"} minLength={8}
                                placeholder={"Zadejte heslo"} isMandatory={(type !== "update")} maxLength={64}/>
                </div>
                <div className="lg:w-1/2 sm:w-1/2 w-full">
                    <InputField name={`${type === "register" ? 'account.passwordCheck' : 'passwordCheck'}`}
                                label={"Kontrola hesla"}
                                inputType={"password"} minLength={8}
                                placeholder={"Zadejte heslo"} isMandatory={(type !== "update")}
                                checkWithValue={type === "register" && values ? values?.account?.password : values.password}
                                maxLength={64}/>
                </div>
                {(type === "create" || type === "update") && <>
                    <div className="w-full">
                        <SelectField name={"userType"} options={userType} label={"Role uživatele"} isMandatory={true}/>
                    </div>
                    <div className="w-full">
                        <SectionSelectField name={"section"} label={"Sekce"} isMulti={true}
                                            isMandatory={false}/>
                    </div>
                </>}
            </div>
        </div>
    )
}
