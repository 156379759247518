import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import Portal from "../../../components/portal";
import {FiSend, FiX} from "react-icons/fi";
import {Button} from "../../../components/My/Button";
import {useParams} from "react-router-dom";
import FileField from "../../../components/FinalForm/FileField";
import {Form} from "react-final-form";
import Toast from "cogo-toast";

const PhotoModal = ({
                        setOpen,
                        open,
                        addPhoto,
                        apiUploadImage
                    }) => {

    let[saving, setSaving] = useState(false);

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.palettes,
        }),
        shallowEqual
    )
    let {background} = {
        ...palettes
    }
    const {id} = useParams();
    const modalRef = useRef(null)
    let [photo, setPhoto] = useState(null);

    const hide = () => {
        setOpen(false)
    }

    const onSuccess = (data)=>{
        if(addPhoto) addPhoto(data)
        setPhoto(undefined)
        setSaving(false)
        hide()
    }

    const onError = (error)=>{
        Toast.error("Při nahrávání fotografie nastala chyba.")
        setSaving(false);
    }

    const onSubmit = (values) => {
        if(photo){
            setSaving(true);
            if(id){
                apiUploadImage(id,{photo:photo},onSuccess,onError)
            }else{
                apiUploadImage({photo:photo},onSuccess,onError)
            }

        }else{
            Toast.error("Vyberte fotografii.")
        }

    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open)
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }

    }, [open, modalRef])

    return (
        <>
            {open && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}  border border-black`}
                         data-background={background}>
                        <div className="relative min-w-sm lg:w-1/3 w-full mx-auto lg:max-w-5xl bg-white rounded" ref={modalRef}>
                            <div className="modal-content">

                                <div className="modal-header">
                                    <h3 className="font-semibold">{"Přidat fotografii"}</h3>
                                    <button
                                        className="modal-close btn btn-transparent"
                                        onClick={hide}>
                                        <FiX size={18} className="stroke-current"/>
                                    </button>
                                </div>
                                <Form onSubmit={onSubmit}
                                      render={({handleSubmit, values, hasSubmitErrors, hasValidationErrors}) => {
                                          return (
                                              <>
                                                  <div className={"m-2"}>
                                                      <span className={"text-xl"}>Vyberte fotografii</span>
                                                      <FileField name={"photo"} setPhoto={setPhoto} isMandatory={true}/>
                                                  </div>
                                                  <div className="modal-footer space-x-2">
                                                      <Button onClick={handleSubmit}
                                                              text={"Odeslat"} loading={saving}
                                                              icon={<FiSend/>}/>
                                                      <Button onClick={hide}
                                                              text={"Zavřít"} loading={saving}
                                                              icon={<FiX/>}/>
                                                  </div>
                                              </>
                                          )
                                      }}/>
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}
export default PhotoModal;
