import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {apiGetAuthRegisterToken, apiGetRegisterToken} from "./Actions";
import {instance} from "../../config/axios-cnf";
import Registration from "./Registration";
import {DefaultLoader} from "../../components/DefaultLoader";
const RegisterApp = () => {

    let [registerToken, setRegisterToken] = useState(null);

    let token = localStorage.getItem("reg-authorization-leg-enigoo");
    const navigate = useNavigate();

    useEffect(() => {
        if(token){
            instance.defaults.headers.common["reg-authorization"] = token;
            apiGetAuthRegisterToken((data)=>{
                setRegisterToken(token);
            },(error)=>{
                localStorage.removeItem("reg-authorization-leg-enigoo");
                apiGetRegisterToken((data)=>{
                    setRegisterToken(data.token);
                    localStorage.setItem("reg-authorization-leg-enigoo",data.token);
                    instance.defaults.headers.common["reg-authorization"] = data.token;
                },(error)=>{
                    setRegisterToken(null);
                    navigate("/");
                })
            })
        }else{
            apiGetRegisterToken((data)=>{
                setRegisterToken(data.token);
                localStorage.setItem("reg-authorization-leg-enigoo",data.token);
                instance.defaults.headers.common["reg-authorization"] = data.token;
            },(error)=>{
                setRegisterToken(null);
                navigate("/");
            })
        }
    }, []);

    if(registerToken) return <Registration/>
    else {
        return <DefaultLoader text={"Načítám data..."}/>
    }

}
export default RegisterApp;
