import React from 'react'
import {useSelector, shallowEqual, useDispatch} from 'react-redux'
import Item from './item'
import Logo from './logo'
import '../../css/components/left-sidebar-1.css'
import {FiActivity, FiLogOut, FiUsers} from "react-icons/fi";
import {apiLogout} from "../../pages/LoginPage/Actions";
import Toast from "cogo-toast";
import {USER_RESET} from "../../reducers/user";
import {Link, useNavigate} from "react-router-dom";
import {GiHomeGarage, GiSettingsKnobs, GoGraph} from "react-icons/all";
import {instance} from "../../config/axios-cnf";


const menuItems = [
    {
        title: 'Menu',
        items: [
            {
                url: '/account/exhibit',
                icon: <GiHomeGarage size={20}/>,
                title: 'Moje exponáty',
                user: [0, 1, 2, 3],
                items: []
            },
            {
                url: '/',
                icon: <GiSettingsKnobs size={20}/>,
                title: 'Nastavení',
                user: [1, 2, 3],
                items: [
                    {
                        url: '/settings/exhibit',
                        icon: <GiHomeGarage size={20}/>,
                        title: 'Exponáty',
                        user: [1, 2,3],
                        items: []
                    },
                    {
                        url: '/settings/user',
                        icon: <FiUsers size={20}/>,
                        title: 'Uživatelé',
                        user: [1, 2],
                        items: []
                    },
                    {
                        url: '/settings/section',
                        icon: <FiActivity size={20}/>,
                        title: 'Sekce',
                        user: [1, 2],
                        items: []
                    },
                    {
                        url: '/settings/analysis',
                        icon: <GoGraph size={20}/>,
                        title: 'Analytická data',
                        user: [1],
                        items: []
                    }
                ]
            },
        ]
    },
]
const Sidebar = () => {

    let dispatch = useDispatch();
    let navigate = useNavigate();

    const logout = () => {


        apiLogout((data) => {
            Toast.success("Byl jste úspěšně odhlášen.")
            dispatch({type: "RESET"})
            localStorage.removeItem('authorization-leg-enigoo')
            instance.defaults.headers.common["Authorization"] = null;
            navigate('/login')
        })
    }

    return (
        <div className="left-sidebar left-sidebar-1">
            <Logo/>
            {menuItems.map((menu, i) => (
                <div key={i}>
                    <div className="left-sidebar-title">
                        <span>{menu.title}</span>
                    </div>
                    <ul>
                        {menu.items.map((l0, a) => (
                            <li key={a} className="l0">
                                <Item {...l0} />
                                <ul>
                                    {l0.items.map((l1, b) => (
                                        <li key={b} className="l1">
                                            <Item {...l1} />
                                            <ul>
                                                {l1.items.map((l2, c) => (
                                                    <li key={c} className="l2">
                                                        <Item {...l2} />
                                                        <ul>
                                                            {l2.items.map((l3, d) => (
                                                                <li key={d} className="l3">
                                                                    <Item {...l3} />
                                                                    <ul>
                                                                        {l3.items.map((l4, e) => (
                                                                            <li key={e} className="l4">
                                                                                <Item {...l4} />
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                        <li className={"l0"}>
                            <Link
                                to={() => {
                                }}
                                onClick={logout}
                                className={`left-sidebar-item`}>
                                <FiLogOut size={20}/>
                                <span className="title">Odhlásit se</span>
                            </Link>
                        </li>

                    </ul>
                </div>
            ))}
        </div>
    )
}

export default Sidebar
