import React, {useEffect, useState} from "react"
import {
    apiChangeForgotPassword,
    apiGetForgotPasswordPage
} from "./Actions";
import Layout from "../../layouts/centered";
import CenteredForm from "../../layouts/centered-form";
import InputField from "../../components/FinalForm/InputField";
import {Form} from "react-final-form";
import {useNavigate, useParams} from "react-router-dom";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../components/DefaultLoader";
import {Button} from "../../components/My/Button";

const SetForgotPassword = () => {

    let [loading, setLoading] = useState(false)
    let [error, setError] = useState(null)
    let [result, setResult] = useState(false)
    let [loadingPass, setLoadingPass] = useState(false)

    let navigate = useNavigate()

    let {code} = useParams();

    useEffect(() => {
        findCode()
    }, [])

    const findCode = () => {
        setLoading(true)
        apiGetForgotPasswordPage(code, (data) => {
            setLoading(false)
            setResult(true)
        }, (error) => {
            setLoading(false)
            switch (error?.type) {
                case "REQUEST_NOT_VALID":
                    setError("E-mail neexistuje.");
                    break;
            }
        })
    }


    const onSubmit = (values) => {
        setError(null)
        setLoadingPass(true)

        let data = {
            code: code,
            password: values.pass1
        }

        apiChangeForgotPassword(data, (data) => {
            setLoadingPass(false)
            Toast.success("Heslo bylo úspěšně změněno, prosím přihlašte se.")
            navigate("/login")
        }, error => {
            setLoadingPass(false);
            switch (error.type) {
                case "LOGIN_ERROR":
                    setError("Špatné přihlašovací údaje.");
                    break;
                case "USER_NOT_VERIFED":
                    setError("Účet nebyl dosud verifikován. Klidněte na odkaz v e-mailu.");
                    break;
                default:
                    setError("Došlo k chybě")
            }
        })
    }

    if (loading) return <DefaultLoader text={"Načítám stránku..."}/>
    if (error) return <div>{error}</div>

    if (result) {
        return (
            <Layout>
                <div className={"flex flex-col w-full max-w-xl"}>
                    <div className={"flex items-center justify-center pb-6"}>
                        <img src={"/assets/logo-legendy.png"} className={"h-48"} alt={"Legendy.cz"}/>
                    </div>
                    <CenteredForm subtitle="Zadejte nové heslo"
                                  title={"Obnova hesla do uživatelské sekce"}>
                        <Form onSubmit={onSubmit}
                              render={({handleSubmit}) => (
                                  <form className="form flex flex-wrap w-full">
                                      <div className="w-full">
                                          <InputField name={"pass1"} label={"Heslo"} inputType={"password"}
                                                      placeholder={"Zadejte heslo"} isMandatory={true}
                                                      onEnter={() => handleSubmit()}/>
                                      </div>
                                      <div className="w-full">
                                          <InputField name={"pass2"} label={"Heslo ověření"} inputType={"password"}
                                                      placeholder={"Zadejte heslo"} isMandatory={true}
                                                      onEnter={() => handleSubmit()}/>
                                      </div>
                                      <Button disabled={loadingPass} onClick={handleSubmit} loading={loadingPass}
                                              text={"Nastavit nové heslo"}/>

                                  </form>
                              )}
                        />
                    </CenteredForm>
                </div>
            </Layout>
        )
    }
    return null

}

export default SetForgotPassword
