import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {ClearCacheProvider} from 'react-clear-cache';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import * as Sentry from "@sentry/react";
import * as serviceWorker from './serviceWorker'
import {BrowserTracing} from "@sentry/tracing";
import version from "../package.json"
import {Provider} from "react-redux";
import store from "./store";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";

if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn: "https://8c7ea7dabd614d818d04c99fa5c33fbc@o1040166.ingest.sentry.io/4504843019223040",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        version: version
    });
}
let per = persistStore(store);
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={per}>
            <Router basename={process.env.NODE_ENV === "development" ? "/" : "/"}>
                <Routes>
                    <Route path={'*'} element={<App/>}/>
                </Routes>
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
