import React from "react";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import Select from "react-select";


const SelectField = (props) => {


    const getParse = (value) => {
        if (value === null) return null;
        return value.value;
    };
    const getValue = (value, values) => {
        if (props.makeDisabled) {
            if (!value) {
                props.makeDisabled(values, props.options);
                return null;
            }

            let info = props.options.find((i) => i.value === value);
            props.makeDisabled(values, props.options, info);
            return props.options.find((i) => i.value === value);
        } else {
            if (!value) {
                return null;
            }
            return props.options.find((i) => i.value === value);
        }
    };

    const errorMessage = (message) => {
        return (
            <span style={{ color: "red", marginRight: "10px" }} className={"text-sm"}>
                <i className="fa fa-arrow-up"> </i> {message}
            </span>
        );
    };

    const renderError = (_props) => {
        if (_props.meta.error && _props.meta.touched) {
            if (!_props.input.value || _props.input.value === "") return errorMessage("Povinné pole");
        }
        return null;
    };

    return (
        <Field
            {...props}
            name={props.name}

            validate={(value) => {
                if (props.isMandatory) {
                    if (!value) return true;
                }
                return false;
            }}
            render={(_props) => (
                <div className={"form-element w-full"} data-test={props.dataTest}>
                    <div className={"form-label font-bold"}>
                        {props.label&& props.label+": "}
                        {props.isMandatory ? <sup className={"text-legendyColor"}>*</sup> : ""}
                    </div>
                    <Select
                        {...props}
                        styles={{
                            menu: (base) => ({ ...base, zIndex: 9999 }),
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        isMulti={props.isMulti}
                        placeholder={props.placeholder ? props.placeholder : "Vyberte..."}
                        onChange={(value) => {
                            _props.input.onChange(value)
                            if(props.onChange){
                                props.onChange(value);
                            }
                        }}
                        options={props.options}
                        value={_props.input.value?getValue(_props.input.value, props.values):null}
                        isDisabled={props.disabled}
                        isClearable
                    // data-test={props.dataTest}
                    />
                    {renderError(_props)}
                </div>
            )}
            parse={getParse}
        />
    );
};

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    isMandatory: PropTypes.bool,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string,
    validate: PropTypes.func,
    value: PropTypes.object,
    dataTest: PropTypes.string,
    disabled: PropTypes.bool
};
SelectField.defaultProps = {
    isMandatory: false,
    isMulti: false,
    disabled: false
};

export default SelectField;
