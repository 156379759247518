export const USER_SET_TOKEN = "USER_SET_TOKEN";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_RESET = "USER_RESET";
export const FETCH_USER_START = "FETCH_USER_START";

export default function reducer(
    state = {
        user: null,
        token: null
    },
    action
) {
    switch (action.type) {
        case FETCH_USER_START: {
            return { ...state, fetching: true, fetched: false, error: null }
        }
        case USER_SET_TOKEN: {
            return {
                ...state,
                token: action.payload
            }
        }
        case USER_FETCH_SUCCESS: {
            return {
                ...state,
                user: action.payload,
            }
        }
        case "RESET":
        case USER_RESET: {
            return {
                ...state,
                user: null,
                token: null
            }
        }
        default:
            return state

    }
}

