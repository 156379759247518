import React from "react";
import store from "./store";
import { Provider } from "react-redux";
import Token from "./Token";


function App() {
    return (
        <Provider store={store}>
            <Token />
        </Provider>
    );
}

export default App;
