import React, {useEffect, useState} from "react";
import EnigooBreadcrumbs from "../../components/breadcrumbs/EnigooBreadcrumbs";
import {Button} from "../../components/My/Button";
import {FiSave} from "react-icons/fi";
import {Form} from "react-final-form";
import {PersonalInfo} from "../Registration/PersonalInfo";
import {apiGetUserById, apiPostCreateUser, apiPutUpdateUser} from "./Actions";
import {useNavigate, useParams} from "react-router-dom";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../components/DefaultLoader";

const UserForm = () => {

    let[data,setData] = useState(null);
    let[saving,setSaving] = useState(false);
    let[loading,setLoading] = useState(false);

    const {id} = useParams();

    const navigation = useNavigate();

    useEffect(()=>{
        if(id){
            setLoading(true);
            apiGetUserById(id,(data)=>{
                setData(data);
                setLoading(false);
            },(error)=>{
                setData(null);
                navigation("/user");
                setLoading(false);
            })
        }

    },[])

    const onSubmit = (values) =>{
        setSaving(true);
        if(id){
            apiPutUpdateUser(id,values,(data)=>{
                Toast.success("Uživatel upraven");
                setSaving(false);
            },(error)=>{
                Toast.error("Chyba při ukládání.\n"+error.message);
                setSaving(false);
            })
        }else{
            apiPostCreateUser(values,(data)=>{
                Toast.success("Uživatel vytvořen");
                navigation("/user/detail/"+data.id);
                setSaving(false);
            },(error)=>{
                Toast.error("Chyba při ukládání.\n"+error.message);
                setSaving(false);
            })
        }
    }

    const _renderBody = (values) => {
        if(loading) return <DefaultLoader text={"Načítám data uživatele..."}/>;
        else return <PersonalInfo values={values} type={`${id?'update':'create'}`}/>;
    }

    return(
        <div className="w-full flex justify-center bg-grey-50">
            <Form onSubmit={onSubmit} initialValues={data}
                  render={({handleSubmit, values, ...props}) => {
                      return (
                          <div className={"flex flex-col w-full items-center"}>
                              <EnigooBreadcrumbs
                                  right={<Button loading={saving} text={"Uložit"} onClick={()=>handleSubmit()} icon={<FiSave/>}/>}/>
                              {_renderBody(values)}
                          </div>
                      )}}/>
        </div>
    )
}

export default UserForm;
