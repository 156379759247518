import storage from "redux-persist/lib/storage";
import {configureStore} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import rootReducer from "./reducers";
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whiteList: ['config','dashboard','filterCar','leftSidebar','navigation','pages','palettes','user']
}

const store = configureStore(
    {
        reducer: persistReducer(persistConfig, rootReducer),
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck:{
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                ignoreState:["navigation"]
            }
        })
    }
)

export default store;
