export default function activeAction(
    state = {
        activeAction: {
            id: null,
            name: null,
            isActive: null,
            food:null
        }
    },
    action
) {
    switch (action.type) {
        case "SET_ACTIVE_ACTION":
            return {
                ...state, activeAction: {
                    id: action.activeAction.id,
                    name: action.activeAction.name,
                    isActive: action.activeAction.isActive,
                    food: action.activeAction.food
                }
            }
        case "RESET":
            return {
                activeAction: {
                    id: null,
                    name: null,
                    isActive: null,
                    food: null
                }
            }
        default:
            return state;
    }
}
