import React, {useEffect, useState} from "react";
import {apiGetAdminExponatById, apiPutAdminExponat, apiPutUploadPhotoAdmin} from "./Actions";
import {useNavigate, useParams} from "react-router-dom";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../components/DefaultLoader";
import {Form} from "react-final-form";
import EnigooBreadcrumbs from "../../components/breadcrumbs/EnigooBreadcrumbs";
import Widget from "../../components/widget";
import {
    AiFillDislike, AiFillInfoCircle, AiFillLike, AiFillWarning, FiPrinter, FiSave, RiExchangeBoxLine,
} from "react-icons/all";
import RadioField from "../../components/FinalForm/RadioField";
import InputField from "../../components/FinalForm/InputField";
import SelectField from "../../components/FinalForm/SelectField";
import CheckboxField from "../../components/FinalForm/CheckboxField";
import {Button} from "../../components/My/Button";
import {ChangeExponatStateModal} from "./ChangeExponatStateModal";
import {ImageGallery} from "../../components/image-modal/ImageGallery";
import {FiPlus} from "react-icons/fi";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ExponatAddToSectionModal} from "./ExponatAddToSectionModal";
import ActionDropDown from "../../components/FinalForm/ActionDropDown";
import {IconTabs} from "../../components/tabs";
import {apiGetExponatById, apiPostExponatNewAction} from "../Exponat/Actions";
import ExponatFormInfo from "../Exponat/Form/ExponatFormInfo";
import ImagesFormDetail from "../Exponat/Form/ImagesFormDetail";
import UserInfoForm from "../Exponat/Form/UserInfoForm";
import ActionDetailForm from "../Exponat/Form/ActionDetailForm";


const AdminExponatDetail = () => {

    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [data, setData] = useState(null);

    let [showModal, setShowModal] = useState(false);
    let [savingState, setSavingState] = useState(false);
    let [assignToSectionOpen, setAssignToSectionOpen] = useState(false);
    let submit = null;
    const {id} = useParams();
    const navigate = useNavigate();

    const queryParam = new URLSearchParams(window.location.search);
    const actionParam = queryParam.get('a');

    const {actualAction} = useSelector(
        state => (state.actualAction),
        shallowEqual
    )
    const {activeAction} = useSelector(
        state => (state.activeAction),
        shallowEqual
    )

    let {userState} = useSelector(
        state => ({
            userState: state.user,
        }),
        shallowEqual
    );

    useEffect(() => {
        getData();
    }, [actualAction])

    const onSubmit = (values) => {
        setSaving(true);
        if (id) {

            apiPutAdminExponat(actualAction.id, id, {data: values}, (data) => {
                let image = [];
                data.image && data.image.map((item, index) => {
                    image.push({src: item.data, title: item.name})
                })
                data.image = image;
                setData(data);
                Toast.success("Exponát byl upraven.");
                setSaving(false);
            }, (error) => {
                Toast.error("Exponát se nepodařilo upravit.");
                setSaving(false);
            })

        }
    }

    const getData = () => {
        setLoading(true);
        let actionId = actualAction.id;
        const onSuccess = (data) => {
            let image = [];
            data.image.map((item, index) => {
                image.push({src: item.data, title: item.name})
            })
            data.image = image;
            setData(data);
            setLoading(false);
        };
        if (actionParam && parseInt(actionParam) !== actualAction.id) {
            actionId = null;
        }
        const onError = (error) => {
            Toast.error("Nepodařilo se načíst data exponátu.");
            setLoading(false);
            navigate("/settings/exhibit")
        }
        if (actionId) {
            apiGetAdminExponatById(actionId, id, onSuccess, onError)
        }


    }
    const tabs = (values) => [
        {
            index: 0,
            title: "Exponát",
            content: <ExponatFormInfo data={data}/>
        },
        {
            index: 1,
            title: "Foto",
            content: <ImagesFormDetail data={data} setData={setData} updateImage={apiPutUploadPhotoAdmin}/>
        },
        {
            index: 2,
            title: "Vystavovatel",
            content: <UserInfoForm/>
        },
        {
            index: 3,
            title: "Detail",
            content: <ActionDetailForm values={values}/>
        }
    ]

    const _renderBody = (values) => {
        if (loading) return <DefaultLoader text={"Načítám data exponátu..."}/>;
        if (data) return (
            <IconTabs tabs={tabs(values)}/>

        )
        return <></>;
    }


    const _renderButtons = (handleSubmit) => {
        let showStateButton = !window.location.pathname.includes("/account/exhibit") && actualAction.id === activeAction.id;
        let cols = 3;
        if (!showStateButton) cols--;

        return <div className={`grid grid-cols-${cols} gap-2`}>
            {showStateButton ? <Button icon={<RiExchangeBoxLine/>} text={"Změnit stav"}
                                       onClick={() => setShowModal(true)}/> : <></>}
            <Button icon={<FiSave/>} loading={saving} text={"Uložit"} onClick={handleSubmit}/>
            <ActionDropDown/>
        </div>
    }

    return (
        <Form onSubmit={onSubmit} initialValues={data}
              render={({handleSubmit, values, ...props}) => {
                  submit = handleSubmit;
                  return (<>
                          <ChangeExponatStateModal setOpen={setShowModal} open={showModal} setData={setData}
                                                   saving={savingState} setSaving={setSavingState}/>
                          <ExponatAddToSectionModal selectedIds={[id]} open={assignToSectionOpen}
                                                    setOpen={setAssignToSectionOpen}/>
                          <EnigooBreadcrumbs right={_renderButtons(handleSubmit)}/>
                          {_renderBody(values)}
                      </>
                  )
              }}
        />
    )


}
export default AdminExponatDetail;
