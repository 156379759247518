import React from "react";
import {Link} from "react-router-dom";
import {FiEdit} from "react-icons/all";
import Datatable from "../../components/datatable";
import {useDispatch, useSelector} from "react-redux";

const SectionTable = ({data}) => {
    let tableData = React.useMemo(() => {
        return data;
    }, [data]);
    const dispatch = useDispatch();
    const {  sectionSize,sectionPage } = useSelector((state) => state.pages);

    let columns = React.useMemo(() => [
        {Header: '#', accessor: 'id'},
        {Header: 'Jméno', accessor: 'name'},
        {
            Header: 'Možnosti', accessor: d => (
                <Link to={"/settings/section/detail/" + d.id}>
                    <button
                        className={"btn btn-default rounded btn-icon bg-grey-500 hover:bg-grey-400 space-x-2 mt-1 px-5 text-white "}>
                        <FiEdit size={20}/>
                    </button>
                </Link>
            )
        }
    ]);

    const pageSelectValueChange = (value) =>{
        dispatch({
            type:'SET_SECTION_PAGE_SIZE',
            payload: value
        })
    }

    const pageChanged = (value) =>{
        dispatch({
            type: 'SET_SECTION_PAGE',
            payload: value
        })
    }

    if (data) return <Datatable data={tableData} columns={columns} onPageChange={pageChanged} defaultPage={sectionPage}
                                onPageSelectChange={pageSelectValueChange} pageSelectDefaultValue={sectionSize}/>
    else return <div>Žádná data</div>
}
export default SectionTable;
