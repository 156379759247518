import React from "react";
import {FiEdit} from "react-icons/all";
import {Link} from "react-router-dom";
import Datatable from "../../components/datatable";
import {useDispatch, useSelector} from "react-redux";

const UserTable = ({data}) => {
    const dispatch = useDispatch();
    let tableData = React.useMemo(() => {
        return data;
    }, [data]);

    const {userSize, userPage} = useSelector((state) => state.pages);

    let columns = React.useMemo(() => [
        {Header: '#', accessor: 'id'},
        {Header: 'E-mail', accessor: 'email'},
        {Header: 'Jméno', accessor: d => (d.firstname + " " + d.surname)},
        {
            Header: 'Role', accessor: d => {
                switch (d.userType) {
                    case 0:
                        return "Majitel exponátu";
                    case 1:
                        return "Ředitel";
                    case 2:
                        return "Koordinátor";
                    case 3:
                        return "Sekční vedoucí";
                    default:
                        return "";
                }
            }
        },
        {
            Header: 'Možnosti', accessor: d => (
                <Link to={"/settings/user/detail/" + d.id}>
                    <button
                        className={"btn btn-default rounded btn-icon bg-grey-500 hover:bg-grey-400 space-x-2 mt-1 px-5 text-white "}>
                        <FiEdit size={20}/>
                    </button>
                </Link>
            )
        }
    ]);

    const pageSelectValueChange = (value) => {
        dispatch({
            type: 'SET_USER_PAGE_SIZE',
            payload: value
        })
    }

    const pageChanged = (value) => {
        dispatch({
            type: 'SET_USER_PAGE',
            payload: value
        })
    }

    if (data) return <Datatable data={tableData} columns={columns} defaultPage={userPage} onPageChange={pageChanged}
                                onPageSelectChange={pageSelectValueChange} pageSelectDefaultValue={userSize}/>
    else return <div>Žádná data</div>
}
export default UserTable;
