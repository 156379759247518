export default function pages(
    state = {
        carSize: 30,
        carPage: 0,
        userSize: 10,
        userPage: 0,
        sectionSize: 10,
        sectionPage: 0
    }, action
) {
    switch (action.type) {
        case "SET_CAR_PAGE":
            return {
                ...state,
                carPage: action.payload
            }
        case "SET_CAR_PAGE_SIZE":
            return {
                ...state,
                carSize: action.payload
            }
        case "SET_USER_PAGE_SIZE":
            return {
                ...state,
                userSize: action.payload
            }
        case "SET_USER_PAGE":
            return {
                ...state,
                userPage: action.payload
            }
        case "SET_SECTION_PAGE":
            return {
                ...state,
                sectionPage: action.payload
            }
        case "SET_SECTION_PAGE_SIZE":
            return {
                ...state,
                sectionSize: action.payload
            }
        case "RESET":
        case "PAGE_RESET":
            return {
                ...state, carPage: 0, userPage: 0, sectionPage: 0, carSize: 30, userSize: 10, sectionSize: 10
            }
        default:
            return state;
    }
}
