import {instance, onErrorFunction} from "../../config/axios-cnf";

export const apiGetSection = (callback,error)=>{
    instance.get("/api/v1/section")
        .then(response => callback(response.data))
        .catch(err =>error(onErrorFunction(err)))
}

export const apiGetSectionById = (id,callback,error)=>{
    instance.get("/api/v1/section/"+id)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPostCreateSection = (data,callback,error)=>{
    instance.post("/api/v1/section",data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiPutUpdateSection = (id,data,callback,error)=>{
    instance.put("/api/v1/section/"+id,data)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiDeleteSection = (id, callback, error) =>{
    instance.delete("/api/v1/section/"+id)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

