import React, {useEffect, useState} from "react";
import {apiGetSection} from "./Actions";
import EnigooBreadcrumbs from "../../components/breadcrumbs/EnigooBreadcrumbs";
import {Button} from "../../components/My/Button";
import {FiPlus} from "react-icons/fi";
import SectionTable from "./SectionTable";
import {DefaultLoader} from "../../components/DefaultLoader";

const Section = ()=>{

    let[data,setData] = useState(null);
    let[loading,setLoading] = useState(false);

    useEffect(()=>{
        setLoading(true);
        apiGetSection((data)=>{
            setData(data);
            setLoading(false);
        },(error)=>{
            setData([]);
            setLoading(false);
        })
    },[])

    return (
        <>
            <EnigooBreadcrumbs right={<Button text={"Přidat sekci"} link={"/settings/section/add"} icon={<FiPlus/>}/>}/>
            {loading ?<DefaultLoader text={"Načítám sekce..."}/> :
                <>
                    <SectionTable data={data}/>
                </>}
        </>
    )
}
export default Section;