import React from "react"
import {Navigate, Route, Routes} from "react-router-dom";
import LoginPage from "./LoginPage";
import Registration from "../Registration/Registration";
import ErrorPage from "../error-page";
import ForgotPassword from "./ForgotPassword";
import SetForgotPassword from "./SetForgotPassword";
import ConfirmAccount from "./ConfirmAccount";
import RegisterApp from "../Registration/RegisterApp";

const LoginRouter = () => {

    return(
        <Routes>
            <Route path={"/"} element={<LoginPage/>}/>
            <Route path={"/login"} element={<LoginPage/>}/>
            <Route path={"/create-account"} element={<RegisterApp/>}/>
            <Route path={"/forgot-password"} element={<ForgotPassword/>}/>
            <Route path={"/set-forgot-password/:code"} element={<SetForgotPassword/>}/>
            <Route path={"/confirm-account/:code"} element={<ConfirmAccount/>}/>
            <Route path={"/account"} element={<LoginPage/>}/>
            <Route path="*" element={<Navigate to={"/"}/>} />
        </Routes>
    )
}
export default LoginRouter
