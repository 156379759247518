import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {
    FiChevronRight,
} from 'react-icons/fi'
import {shallowEqual, useDispatch, useSelector} from "react-redux";

const Item = ({url, icon, title, badge, items, onClick = null,user}) => {
    const [hidden, setHidden] = useState(true)
    let location = useLocation()
    let {pathname} = {...location}
    let active = pathname === url ? true : false
    if (pathname === '/' && url === '/dashboard') {
        active = true
    }
    if (pathname === '/' && url !== '/dashboard') {
        active = false
    }

    let {userState} = useSelector(
        state =>({
            userState: state.user
        }),
        shallowEqual
    );

    const show = user && user.some(v=>(v===userState.user.user_type));
    if(!show) return <></>;

    if (items.length === 0) {
        return (
            <Link
                to={!onClick ? url : () => {
                }}
                onClick={onClick ? onClick : () => {
                }}
                className={`left-sidebar-item ${active ? 'active' : ''}`}>
                {icon}
                <span className="title">{title}</span>
            </Link>
        )
    }
    return (
        <button
            onClick={() => setHidden(!hidden)}
            className={`left-sidebar-item ${active ? 'active' : ''} ${
                hidden ? 'hidden-sibling' : 'open-sibling'
            }`}>
            {icon}
            <span className="title">{title}</span>
            <FiChevronRight className="ml-auto arrow"/>
        </button>
    )
}

export default Item
