import React, {useState} from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";

import "./css/FileCss.css"
import {useFilePicker} from "use-file-picker";
import {Button} from "../My/Button";
import {FiX} from "react-icons/fi";

const FileField = (props) => {

    let photo = null;
    let [error,setError] = useState(null);

    const [openFileSelector, {filesContent, errors,loading, clear,}] = useFilePicker({
        accept: ['.jpg','.jpeg', '.gif', '.png'],
        readAs: 'DataURL',
        multiple: false,
        limitFilesConfig: {max: 1},
        maxFileSize: 7.0
    });

    const validateImage=(data) =>{
        let matches = data.match(/^data:image\/(\w+);base64,/);
        if(matches===null) return false;
        let type = matches[1].toLowerCase();
        let types = ['jpg', 'jpeg', 'gif', 'png'];
        return types.some(i=>i===type);
    }

    React.useMemo(() => {
        setError(null);
        if (filesContent.length > 0) {
            if(!validateImage(filesContent[0].content)){
                setError("Vybraný soubor není obrázek");
            }else{
                setError(null);
                photo = {name: filesContent[0].name, value: filesContent[0].content};
                props.setPhoto(photo);
            }
        }
    }, [filesContent])




    const errorMessage = (message) => {
        return <span style={{color: "red", marginRight: "10px"}}><i className="fa fa-arrow-up"> </i> {message}</span>;
    }

    const renderError = (_props) => {
        if(errors.length>0 &&errors[0].readerError){
            return errorMessage("Fotografii se nepodařilo načíst")
        }
        if(errors.length>0 && errors[0].fileSizeToolarge){
            return errorMessage("Fotografie je příliš velká")
        }
        if (!props.viewError) return null
        if (_props.meta.error && _props.meta.touched) {
            if (!_props.input.value || _props.input.value === "") return errorMessage("Povinné pole")

        }
        return null;
    }


    return (
        <div className={"w-full mb-1"}>
            <Field
                name={props.name}
                validate={(value) => {
                    if(error){
                        return true;
                    }
                    if (props.isMandatory) {
                        return photo!=null;
                    }
                }}
                render={(_props) => {
                    return (
                        <div className={"flew flex-row w-full"}>
                            <div className={"flex flex-row w-full"}>
                                <div className={"w-1/3"}><Button onClick={() => openFileSelector()} text={"Vybrat soubor"}/></div>
                                <div className={"w-2/3 pt-2"}>
                                    {filesContent && filesContent[0] ? <div className={"flex flex-row"}>{filesContent[0].name} <button onClick={()=>{
                                        clear();
                                        props.setPhoto(null);
                                    }} className={"ml-2"}><FiX color={"red"}/></button></div>  : "Nevybrán žádný soubor"}
                                </div>
                            </div>
                            <div className={"flex flex-col"}>
                                {renderError(_props)}
                                {error && errorMessage(error)}
                            </div>
                        </div>
                    )
                }}
            />
        </div>
    )
}

FileField.defaultProps = {
    viewError: true,
    isMandatory: true
}

FileField.propTypes = {
    viewError: PropTypes.bool,
    isMandatory: PropTypes.bool
}

export default FileField
