import React, {useEffect} from "react";
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import {NavLink, useLocation} from "react-router-dom";
import {FiChevronsRight} from "react-icons/fi";
import Widget from "../widget";
import {useSelector} from "react-redux";

const getRoutes=(user)=>{
    const routes = [];
    if(user.user_type===1||user.user_type===2){
        routes.push({path: "/", breadcrumb: "Domů"})
        routes.push({path: "/account", breadcrumb: null})
    }else{
        routes.push({path: "/", breadcrumb: null})
        routes.push({path: "/account", breadcrumb: "Domů"})
    }
    routes.push({path:"/account/exhibit",breadcrumb: "Moje exponáty"});
    routes.push({path:"/account/exhibit/detail",breadcrumb: null});
    routes.push({path:"/account/exhibit/detail/:id",breadcrumb: "Detail"});
    routes.push({path:"/account/exhibit/add",breadcrumb: "Nový"});
    routes.push({path:"/:account/exhibit/action",breadcrumb: null});
    routes.push({path:"/:account/exhibit/action/:id",breadcrumb: "Nový ročník"});
    routes.push({path:"/settings",breadcrumb: null});
    routes.push({path:"/settings/user",breadcrumb: "Uživatelé"});
    routes.push({path:"/settings/user/add",breadcrumb: "Nový"});
    routes.push({path:"/settings/user/detail",breadcrumb: null});
    routes.push({path:"/settings/user/detail/:id",breadcrumb: "Detail"});
    routes.push({path:"/settings/section",breadcrumb: "Sekce"});
    routes.push({path:"/settings/section/add",breadcrumb: "Nová"});
    routes.push({path:"/settings/section/detail",breadcrumb: null});
    routes.push({path:"/settings/section/detail/:id",breadcrumb: "Detail"});
    routes.push({path:"/settings/exhibit",breadcrumb: "Exponáty"});
    routes.push({path:"/settings/exhibit/detail",breadcrumb: null});
    routes.push({path:"/settings/exhibit/detail/:id",breadcrumb: "Detail"});

    routes.push({path:"/settings/analysis",breadcrumb: "Analytická data"});

    return routes;
}


const EnigooBreadcrumbs = ({right}) => {
    const { token, user } = useSelector((state) => state.user);
    const breadcrumbs = useBreadcrumbs(getRoutes(user));
    const location = useLocation();

    return (
        <div className={"w-full"}>
            <Widget>
                <div className="flex flex-row items-center justify-between">
                    <div className={"flex flex-row text-black"}>
                        {breadcrumbs.map(({match,breadcrumb})=>(
                            <span key={match.pathname} className={"flex flex-row items-center justify-start"}>
                            <div className={location.pathname === match.pathname  ? "font-bold text-xl" : "underline"}>
                            <NavLink to={(match.pathname==="/"||match.pathname==="/account")?"/account/exhibit":match.pathname}>{breadcrumb}</NavLink></div>
                                {location.pathname !== match.pathname ?
                                    <FiChevronsRight className="h-3 w-3 mr-2 stroke-current"/> : <></>}
                            </span>
                        ))}
                    </div>
                    <span className="ml-auto"></span>
                    {right}
                </div>
            </Widget>
        </div>
    )
};
export default EnigooBreadcrumbs;
