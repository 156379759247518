import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {
    AiFillDislike, AiFillInfoCircle, AiFillLike, AiFillWarning, AiOutlineCar, FaTractor,
    FiEdit, FiPrinter, MdInsertPhoto, RiCaravanLine, RiCarLine, RiMotorbikeFill, RiMotorbikeLine, RiTruckLine,
} from "react-icons/all";
import Datatable from "../../components/datatable";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import ReactTooltip from "react-tooltip";

const AdminExponatTable = ({data, setSelectedIds}) => {
    const dispatch = useDispatch();
    useEffect(() => {

    }, [])
    const {carSize, carPage} = useSelector((state) => state.pages);
    let tableData = React.useMemo(() => {
        //console.log(carPage);
        return data;
    }, [data]);

    const getState = (exponat) => {
        let toolTip = "";

        switch (exponat.detail.state) {
            case 0:
                /*toolTip = "<p>Čeká na schválení</p>";
                return <><AiFillInfoCircle style={{color: "mediumblue"}} size={25} data-tip={toolTip}/><ReactTooltip
                    html={true}/></>*/
                return <p style={{color: "mediumblue"}}>Čeká na schálení</p>
            case 1:
                /*toolTip = "<p>Schváleno</p>"
                return <><AiFillLike style={{color: "green"}} size={25} data-tip={toolTip}/><ReactTooltip
                    html={true}/></>*/
                return <p style={{color: "green"}}>Schváleno</p>

            case 2:
                /*toolTip = "<p>Je potřeba doplnit informace</p>"
                return <><AiFillWarning style={{color: "orange"}} size={25} data-tip={toolTip}/><ReactTooltip
                    html={true}/> </>*/
                return <p style={{color: "orange"}}>Je potřeba doplnit informace</p>
            case 3:
                /*toolTip = "<p>Neschváleno</p>"
                return <><AiFillDislike style={{color: "red"}} size={25} data-tip={toolTip}/><ReactTooltip
                    html={true}/> </>*/
                return <p style={{color: "red"}}>Neschváleno</p>
            default:
                return <></>;
        }
    }

    const getType = (exponat) => {
        let toolTip = "";
        switch (exponat.type) {
            case 0:
                /*toolTip = "<p>Automobil</p>"
                return <>
                    <div data-tip={toolTip}><RiCarLine size={25}/></div>
                    <ReactTooltip
                        html={true}/></>;*/
                return "Automobil"
            case 1:
                /* toolTip = "<p>Motocykl</p>"
                 return <>
                     <div data-tip={toolTip}><RiMotorbikeLine size={25}/></div>
                     <ReactTooltip
                         html={true}/></>;*/

                return "Motocykl"
            case 2:
                /*toolTip = "<p>Karavan</p>"
                return <>
                    <div data-tip={toolTip}><RiCaravanLine size={25}/></div>
                    <ReactTooltip
                        html={true}/></>;*/
                return "Karavan"
            case 3:
                /*toolTip = "<p>Nákladní auto</p>"
                return <>
                    <div data-tip={toolTip}><RiTruckLine size={25}/></div>
                    <ReactTooltip
                        html={true}/></>;*/
                return "Nákladní auto"
            case 4:
                /*toolTip = "<p>Jiný</p>"
                return <>
                    <div data-tip={toolTip}><FaTractor size={25}/></div>
                    <ReactTooltip
                        html={true}/></>;*/
                return "Jiný"
            default:
                return ""

        }
    }

    const getPavilon = (exponat) => {
        switch (exponat.detail.pavilon) {
            case "supersport":
                return "Pavilon Supersportů";
            case "tematicky":
                return "Tématický pavilon";
            case "motocykly":
                return "Pavilon Motocyklů";
            case "venku":
                return "Venkovní expozice";
        }
    }

    const getPrinted = (exponat) => {
        if (exponat.detail.printed) {
            return <div className={"w-full mx-auto"}><FiPrinter size={20} data-tip={"Vytištěno"}/></div>;
        } else {
            return <div className={"w-full mx-auto"}><span data-tip={"Nevytištěno"}>-</span></div>;
        }

    }

    const pageSelectValueChange = (value) => {
        dispatch({
            type: 'SET_CAR_PAGE_SIZE',
            payload: value
        })
    }

    const pageChanged = (value) => {
        dispatch({
            type: 'SET_CAR_PAGE',
            payload: value
        })
    }

    let columns = React.useMemo(() => [
        {Header: 'Typ', accessor: d => getType(d)},
        {Header: 'Název', accessor: 'nameExponat'},
        {
            Header: 'Foto', accessor: d => {
                if (d.image && d.image.data) {
                    return <>
                        <MdInsertPhoto size={15}
                                       data-tip={"<img alt='st' src='" + d.image.data + "' class='h-64 mx-auto rounded-lg'/>"}/>
                        <ReactTooltip html={true}/>
                    </>
                }

            }
        },
        {Header: 'E-mail', accessor: "user.email"},
        {Header: 'Tisk', accessor: d => getPrinted(d)},
        {
            Header: 'Datum přidání',
            accessor: 'dateCreate',
            Cell: ({cell: {value}}) => <div>{value && moment(value).format("DD.MM.YYYY HH:mm:ss")}</div>,
            sortMethod: (x, y) => (new Date(x) > new Date(y) ? x : y)
        },
        {Header: 'Pavilon', accessor: d => getPavilon(d)},
        {Header: 'Stav', accessor: d => getState(d)},
        {
            Header: 'Sekce', accessor: d => {
                if (d.section) {
                    return d.section.name;
                } else {
                    return "-";
                }
            }
        },
        {
            Header: 'Možnosti', accessor: d => (
                <Link to={"/settings/exhibit/detail/" + d.id}>
                    <button
                        className={"btn btn-default rounded btn-icon bg-grey-500 hover:bg-grey-400 space-x-2 mt-1 px-5 text-white "}>
                        <FiEdit size={20}/>
                    </button>
                </Link>
            )
        }
    ], []);
    return <Datatable data={tableData} columns={columns} setSelectedRows={setSelectedIds} selectable={true}
                      onPageChange={pageChanged} defaultPage={carPage}
                      onPageSelectChange={pageSelectValueChange} pageSelectDefaultValue={carSize}/>
}
export default AdminExponatTable;
