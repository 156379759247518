import React, {useState} from "react"
import {apiSendForgotPassword} from "./Actions";
import Layout from "../../layouts/centered";
import CenteredForm from "../../layouts/centered-form";
import Alert from "../../components/alerts";
import {Form} from "react-final-form";
import {useDispatch} from "react-redux";
import EmailField from "../../components/FinalForm/EmailField";
import {Button} from "../../components/My/Button";
import {Link} from "react-router-dom";

const ForgotPassword = () => {


    let [loading, setLoading] = useState(false)
    let [error, setError] = useState(null)
    let [result, setResult] = useState(false)

    const onLogin = (values) => {
        setError(null)
        setLoading(true)
        apiSendForgotPassword(values, (data) => {
            setLoading(false)
            setResult(true)
        }, error => {
            setLoading(false);
            switch (error?.type) {
                case "EMAIL_ERROR":
                    setError("E-mail neexistuje.");
                    break;
                case "USER_NOT_VERIFED":
                    setError("Účet nebyl dosud verifikován. Klidněte na odkaz v e-mailu.");
                    break;
                default:
                    setError("Došlo k chybě. Opakujte akci znovu.")
            }
        })
    }

    return (
        <Layout>
            <div className={"flex flex-col w-full max-w-xl"}>
                <div className={"flex items-center justify-center pb-6"}>
                    <img src={"/assets/logo-legendy.png"} className={"h-48"} alt={"Legendy.cz"}/>
                </div>
                <CenteredForm subtitle="Obnova hesla do uživatelské sekce" title={""}>
                    {error && <Alert color={"text-red-500"} outlined={true}>{error}</Alert>}
                    {result &&
                        <Alert color={"text-green-500"} outlined={true}>Úspěšně odesláno. Prosím přihlašte se do Vašeho
                            e-mailu.</Alert>}
                    <Form onSubmit={onLogin}
                          render={({handleSubmit}) => (
                              <form className="form flex flex-wrap w-full">
                                  <div className="w-full">
                                      <EmailField name={"email"} label={"E-mail"}
                                                  placeholder={"Přihlašovací e-mail"} isMandatory={true}
                                                  onEnter={() => handleSubmit()}/>
                                  </div>
                                  <Button onClick={handleSubmit} text={"Potvrdit"} loading={loading}/>
                              </form>
                          )}
                    />
                </CenteredForm>
                <div className={"justify-center w-full text-center pt-2"}>
                    <Link to={"/login"} className={"text-legendyColor text-base"}>Zpět na přihlášení</Link>
                </div>
            </div>
        </Layout>
    )
}

export default ForgotPassword
