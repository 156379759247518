import {ImageGallery} from "../../../components/image-modal/ImageGallery";
import FileField from "../../../components/FinalForm/FileField";
import React, {useEffect, useState} from "react";
import {Button} from "../../../components/My/Button";
import PhotoModal from "./PhotoModal";
import {FiPlus} from "react-icons/fi";
import {apiPostDeletePhoto, apiPostNewExponatPhoto, apiPutUploadPhoto} from "../Actions";
import {apiPutDeleteRegistrationImage} from "../../Registration/Actions";
import Toast from "cogo-toast";

const ImagesNewExponatForm = ({
                                  title = "FOTOGRAFIE",
                                  part = 2,
                                  data,
                                  setData,
                                  type = "new"
                              }) => {
    let [showModal, setShowModal] = useState(false);
    let images = [
        {src: "https://legendy-registrace.enigoo.cz/car_photo_1.jpeg", title: "Interiér"},
        {src: "https://legendy-registrace.enigoo.cz/car_photo_2.jpeg", title: "Z boku"},
        {src: "https://legendy-registrace.enigoo.cz/car_photo_3.jpeg", title: "Ze zadu"},
        {src: "https://legendy-registrace.enigoo.cz/car_photo_4.jpeg", title: "Ze předu"},
    ]

    useEffect(() => {

    }, [data]);

    return <div className={`flex flex-col bg-white border border-grey-200 lg:p-8 p-2 pb-8 w-full mb-2 text-base`}>
        <div className="flex flex-row w-full mb-6">
            <h4 className="font-bold"><span className={"text-legendyColor"}>ČÁST {part}</span> -
                {title}</h4>
        </div>


        <div className={"mr-2 font-bold"}>PŘIDÁNÍ NOVÉ FOTOGRAFIE:</div>
        <div>(Jedna fotografie o maximální velikosti 5 MB)</div>
        <div className={"mt-4 mb-2"}>Vzorové fotografie:</div>
        <ImageGallery images={images} showTitle={true}/>
        <Button onClick={() => setShowModal(true)} text={"Přidat fotografii"} icon={<FiPlus/>}/>
        {data && data.length>0 ? <>
            <div className={"mr-2 font-bold"}>JIŽ NAHRANÉ FOTOGRAFIE:</div>
            <ImageGallery images={data ? data : []}
                          onDelete={(index) => {
                              let photo = data[index];
                              apiPostDeletePhoto({
                                  id: photo.id,
                                  name: photo.name
                              }, (result) => {
                                  const newData = [...data.slice(0, index), ...data.slice(index + 1)];
                                  setData(newData)
                                  Toast.success("Obrázek byl odstraněn.")
                              }, (error) => {
                                  Toast.error("Nepodařilo se odstranit obrázek.")
                              })
                          }}
            /></> : <></>}
        <PhotoModal apiUploadImage={apiPostNewExponatPhoto} setOpen={setShowModal} open={showModal} addPhoto={(photo) => {
            if (setData) {
                let images = data;
                images.push({id: photo.id,src: photo.data, name: photo.name})
                setData(images);
                console.log(data);
            }
        }}/>
    </div>
}
export default ImagesNewExponatForm;
