import React from "react";
import {Field} from "react-final-form";
import PropTypes from "prop-types";

const CheckboxField = (props) => {


    const errorMessage = (message) => {
        return <span style={{color: "red", marginRight: "10px"}}><i className="fa fa-arrow-up"> </i> {message}</span>;
    }

    const renderError = (_props) => {
        if (!props.viewError) return null
        if (_props.meta.error && _props.meta.touched) {
            if (!_props.input.value || _props.input.value === "") return errorMessage("Povinné pole")
        }
        return null;
    }


    return (
        <div className={"mr-1"}>
            <Field name={props.name}
                   validate={(value) => {
                       if (props.isMandatory) {
                           if (!value || value.length === 0) return true
                       }
                   }}
                   disabled={props.disabled}
                   id={props.name + props.value}
                   value={props.value}
                   component={"input"}
                   type={"checkbox"}
            />
            <label className={"ml-1 cursor-pointer"} htmlFor={props.name + props.value}>
                {props.label}
            </label>
        </div>
    )
}

CheckboxField.defaultProps = {
    viewError: true,
    isMandatory: true
}

CheckboxField.propTypes = {
    viewError: PropTypes.bool,
    isMandatory: PropTypes.bool
}

export default CheckboxField
