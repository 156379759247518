import React, {useEffect, useState} from "react";
import {apiGetAllAnalysis, apiGetAnalysis, apiGetPrintAnalyis} from "./Actions";
import {DefaultLoader} from "../../components/DefaultLoader";
import EnigooBreadcrumbs from "../../components/breadcrumbs/EnigooBreadcrumbs";
import {Button} from "../../components/My/Button";
import {
    FiDownload
} from "react-icons/all";
import Widget from "../../components/widget";
import Toast from "cogo-toast";
import {shallowEqual, useSelector} from "react-redux";
import {FiPlus} from "react-icons/fi";
import ActionDropDown from "../../components/FinalForm/ActionDropDown";


const Analysis = () => {

    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [downloading, setDownloading] = useState(false);
    let [downloadingAll, setDownloadingAll] = useState(false);

    let {userState} = useSelector(
        state => ({
            userState: state.user
        }),
        shallowEqual
    );
    const {actualAction} = useSelector(
        state => (state.actualAction),
        shallowEqual
    )

    useEffect(() => {
        setLoading(true);
        apiGetAnalysis(actualAction.id,(data) => {
            setData(data);
            setLoading(false);
        }, (error) => {
            setLoading(false);
            setData(null);
        })
    }, [actualAction])

    const renderBody = () => {
        if (loading) return <DefaultLoader text={"Načítám analýzu"}/>;
        if (data) return <AnalysisWidget/>
        else return <>ŽÁDNÁ DATA</>
    }

    const tabs = [
        {
            index: 0,
            title: "Exponáty",
            content: <><ExponatAnalysis data={data}/></>,
        },
        {
            index: 1,
            title: "Účastníci",
            content: <><UserAnalysis data={data}/></>,
        },
        {
            index: 2,
            title: "Jídlo a pití",
            content: <><DrinkFoodAnalysis data={data}/></>,
        }
    ]

    const AnalysisWidget = () => {
        return <Widget>
            <div className={"flex flex-col"}>
                <ExponatAnalysis data={data}/>
                <Entrances data={data}/>
                <UserAnalysis data={data}/>
                <DrinkFoodAnalysis data={data}/>
            </div>
        </Widget>
    }

    const download = () => {
        setDownloading(true);
        apiGetPrintAnalyis(actualAction.id,(data) => {
            if (data.error && data.error.length > 0) {
                Toast.error("Nepodařilo se stáhnout analytická data.");
                setDownloading(false);
            } else {
                exportData(data);
            }
        }, (error) => {
            Toast.error("Nepodařilo se stáhnout analytická data.");
            setDownloading(false);
        })
    }

    const downloadAll = () => {
        setDownloadingAll(true);
        apiGetAllAnalysis(actualAction.id,(data) => {
            if (data.error && data.error.length > 0) {
                Toast.error("Nepodařilo se stáhnout data.");
                setDownloading(false);
            } else {
                exportData(data);
            }
        }, (error) => {
            Toast.error("Nepodařilo se stáhnout data.");
            setDownloadingAll(false);
        })
    }

    const exportData = (data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
        setDownloadingAll(false);
    }

    const _renderButtons = () =>(
        <div className={`grid ${userState.user.user_type === 1 ? "grid-cols-3" : "grid-cols-2"} gap-2`}>
            {userState.user.user_type === 1 ?  <Button text={"Export dat"} icon={<FiDownload size={20}/>}
                                                       onClick={() => downloadAll()} loading={downloadingAll}
            />:<></>}
            <Button text={"Stáhnout"} onClick={() => download()} loading={downloading}
                    icon={<FiDownload size={20}/>}/>
            <ActionDropDown/>
        </div>
    )

    return (
        <>
            <EnigooBreadcrumbs right={_renderButtons()}/>
            {renderBody()}
        </>
    )
}

const ExponatAnalysis = ({data}) => {
    let tdStyle = "text-center border";
    return (
        <>
            <span className={"w-full text-lg font-bold m-3"}>Exponáty</span>
            <div className={"w-full overflow-x-auto mt-2 mb-5"}>
                <table className={"table-fixed border text-base w-full"}>
                    <thead>
                    <tr>
                        <th className={tdStyle}></th>
                        <th className={tdStyle}>Schváleno</th>
                        <th className={tdStyle}>Neschváleno</th>
                        <th className={tdStyle}>Čeká</th>
                        <th className={tdStyle}>Je třeba doplnit údaje</th>
                        <th className={tdStyle}>Celkem</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className={"bg-grey-100"}>
                        <td className={tdStyle}>
                            <div className={"w-full text-lg font-bold"}>Automobily</div>
                        </td>
                        <td className={tdStyle}>{data.perType.car.approved}</td>
                        <td className={tdStyle}>{data.perType.car.noApproved}</td>
                        <td className={tdStyle}>{data.perType.car.wait}</td>
                        <td className={tdStyle}>{data.perType.car.needAddData}</td>
                        <td className={tdStyle}>{data.perType.car.sum}</td>
                    </tr>
                    <tr>
                        <td className={tdStyle}>
                            <div className={"w-full text-lg font-bold"}>Motocykly</div>
                        </td>
                        <td className={tdStyle}>{data.perType.moto.approved}</td>
                        <td className={tdStyle}>{data.perType.moto.noApproved}</td>
                        <td className={tdStyle}>{data.perType.moto.wait}</td>
                        <td className={tdStyle}>{data.perType.moto.needAddData}</td>
                        <td className={tdStyle}>{data.perType.moto.sum}</td>
                    </tr>
                    <tr className={"bg-grey-100"}>
                        <td className={tdStyle}>
                            <div className={"w-full text-lg font-bold"}>Karavany</div>
                        </td>
                        <td className={tdStyle}>{data.perType.caravan.approved}</td>
                        <td className={tdStyle}>{data.perType.caravan.noApproved}</td>
                        <td className={tdStyle}>{data.perType.caravan.wait}</td>
                        <td className={tdStyle}>{data.perType.caravan.needAddData}</td>
                        <td className={tdStyle}>{data.perType.caravan.sum}</td>
                    </tr>
                    <tr>
                        <td className={tdStyle}>
                            <div className={"w-full text-lg font-bold"}>Nákladní auta</div>
                        </td>
                        <td className={tdStyle}>{data.perType.tir.approved}</td>
                        <td className={tdStyle}>{data.perType.tir.noApproved}</td>
                        <td className={tdStyle}>{data.perType.tir.wait}</td>
                        <td className={tdStyle}>{data.perType.tir.needAddData}</td>
                        <td className={tdStyle}>{data.perType.tir.sum}</td>
                    </tr>
                    <tr className={"bg-grey-100"}>
                        <td className={tdStyle}>
                            <div className={"w-full text-lg font-bold"}>Jiné</div>
                        </td>
                        <td className={tdStyle}>{data.perType.other.approved}</td>
                        <td className={tdStyle}>{data.perType.other.noApproved}</td>
                        <td className={tdStyle}>{data.perType.other.wait}</td>
                        <td className={tdStyle}>{data.perType.other.needAddData}</td>
                        <td className={tdStyle}>{data.perType.other.sum}</td>
                    </tr>
                    <tr className={"border-t-2 border-black"}>
                        <td className={tdStyle}>
                            <div className={"w-full text-lg font-bold"}>Celkem</div>
                        </td>
                        <td className={tdStyle}>{data.all.approved}</td>
                        <td className={tdStyle}>{data.all.noApproved}</td>
                        <td className={tdStyle}>{data.all.wait}</td>
                        <td className={tdStyle}>{data.all.needAddData}</td>
                        <td className={tdStyle}>{data.all.sum}</td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </>
    );
}

const UserAnalysis = ({data}) => {
    let tdStyle = "text-center border";
    return (<>
        <span className={"w-full text-lg font-bold m-3"}>Účastníci</span>
        <div className={"w-full overflow-x-auto mt-2 mb-5"}>
            <table className={"table-fixed border text-base w-full"}>
                <thead>
                <tr>
                    <th className={tdStyle}>Řidiči</th>
                    <th className={tdStyle}>Doprovod</th>
                    <th className={tdStyle}>Celkem</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={tdStyle}>{data.user.driver}</td>
                    <td className={tdStyle}>{data.user.secondDriver}</td>
                    <td className={tdStyle}>{data.user.sum}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </>);
}

const Entrances = ({data}) => {
    let tdStyle = "text-center border";
    return (<>
        <span className={"w-full text-lg font-bold m-3"}>Vjezd do areálu</span>
        <div className={"w-full overflow-x-auto mt-2 mb-5"}>
            <table className={"table-fixed border text-base w-full"}>
                <thead>
                <tr>
                    <th className={tdStyle}>Vjezd</th>
                    <th className={tdStyle}>Počet</th>
                </tr>
                </thead>
                <tbody>
                {data.entrances.map((i,key) =>
                    <tr>
                        <td className={tdStyle}>V-{key}</td>
                        <td className={tdStyle}>{i.count}</td>
                    </tr>)
                }
                </tbody>
            </table>
        </div>
    </>);
}

const DrinkFoodAnalysis = ({data}) => {
    let tdStyle = "text-center border";
    return (
        <>
            <span className={"w-full text-lg font-bold m-3"}>Nápoje</span>
            <div className={"w-full overflow-x-auto mt-2 mb-5"}>
                <table className={"table-fixed border text-base w-full"}>
                    <thead>
                    <tr>
                        <th className={tdStyle}>Káva</th>
                        <th className={tdStyle}>Limonáda</th>
                        <th className={tdStyle}>Nealko pivo</th>
                        <th className={tdStyle}>Pivo</th>
                        <th className={tdStyle}>Víno</th>
                        <th className={tdStyle}>Voda</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className={tdStyle}>{data.drink.kava}</td>
                        <td className={tdStyle}>{data.drink.limo}</td>
                        <td className={tdStyle}>{data.drink.nealko}</td>
                        <td className={tdStyle}>{data.drink.pivo}</td>
                        <td className={tdStyle}>{data.drink.vino}</td>
                        <td className={tdStyle}>{data.drink.voda}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <span className={"w-full text-lg font-bold m-3"}>Jídlo</span>
            <div className={"w-full overflow-x-auto mt-2 mb-5"}>
                <table className={"table-fixed border text-base w-full"}>
                    <thead>
                    <tr>
                        {data.food.map((day, index) => {
                            return (
                                <th key={index} className={tdStyle} colSpan={day.count}>{day.label}</th>
                            )
                        })}
                    </tr>
                    <tr>
                        {data.food.map((day) => {
                            return (
                                day.values.map((value, index) => {
                                    return (
                                        <th key={index} className={tdStyle} colSpan={value.count}>{value.label}</th>
                                    )
                                })
                            )
                        })}
                    </tr>
                    <tr>
                        {data.food.map((day) => (
                            day.values.map((value) => (
                                value.options.map((option, index) => (
                                    <th key={index} className={tdStyle}>Jídlo {option.id}</th>
                                ))
                            ))
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {data.food.map((day) => (
                            day.values.map((value) => (
                                value.options.map((option, index) => (
                                    <td key={index} className={tdStyle}>{option.count}</td>
                                ))
                            ))
                        ))}
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Analysis;
