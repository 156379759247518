import {instance, onErrorFunction} from "../../config/axios-cnf";

export const apiGetAnalysis = (actionId,callback, error) => {
    instance.get("/api/v1/analysis/"+actionId)
        .then(response => callback(response.data))
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetPrintAnalyis = (actionId,callback, error) => {
    instance.get("/api/v1/analysis/"+actionId+"/print",{responseType:"blob"})
        .then(response => {
            let fileName = response.headers['content-disposition'].toString();
            fileName = fileName.substring(fileName.indexOf("=") + 1);
            let data = response.data;
            let array = [];
            array["fileName"] = fileName;
            array["data"] = data;
            callback(array);
        })
        .catch(err => error(onErrorFunction(err)))
}

export const apiGetAllAnalysis = (actionId,callback,error) =>{
    instance.get("/api/v1/analysis/"+actionId+"/all",{responseType:"blob"})
        .then(response => {
            let fileName = response.headers['content-disposition'].toString();
            fileName = fileName.substring(fileName.indexOf("=") + 1);
            let data = response.data;
            let array = [];
            array["fileName"] = fileName;
            array["data"] = data;
            callback(array);
        })
        .catch(err => error(onErrorFunction(err)))
}
