import React, {useEffect, useRef, useState} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {FiX} from "react-icons/fi";
import {FiSave} from "react-icons/all";
import CogoToast from "cogo-toast";
import {apiCopyUserToAction, apiPostAssignExponatToSection, apiPutAdminExponat} from "./Actions";
import {Form} from "react-final-form";
import Portal from "../../components/portal";
import {Button} from "../../components/My/Button";
import Toast from "cogo-toast";
import {DefaultLoader} from "../../components/DefaultLoader";
import {SectionSelectField} from "../Section/SectionSelectField";

export const ExponatAddToSectionModal = ({
                                             setOpen,
                                             open,
                                             selectedIds = null,
                                         }) => {
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);

    const {palettes} = useSelector(
        (state) => ({
            palettes: state.palettes,
        }),
        shallowEqual
    )
    let {background} = {
        ...palettes
    }

    const modalRef = useRef(null)

    const hide = () => {
        setOpen(false)
    }


    const send = (values) => {
        if (selectedIds && selectedIds.length > 0) {
            setSaving(true);
            values.id = selectedIds;
            apiPostAssignExponatToSection(values, (data) => {
                Toast.success("Exponáty byli přiřazeny do sekce");
                hide();
                setSaving(false);
                window.location.reload();
            }, (error) => {
                CogoToast.error("Nepodařilo se uživatele duplikovat do akce.");
                setSaving(false);
            })
        }
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!modalRef || !modalRef.current) return false
            if (!open || modalRef.current.contains(event.target)) {
                return false
            }
            setOpen(!open)
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }

    }, [open, modalRef])

    return (
        <>
            {open && (
                <Portal selector="#portal">
                    <div className="modal-backdrop fade-in"></div>
                    <div className={`modal show ${background === 'dark' ? 'dark-mode' : ''}`}
                         data-background={background}>
                        <div className="relative min-w-sm w-1/3 mx-auto lg:max-w-5xl bg-white rounded" ref={modalRef}>
                            <div className="modal-content">
                                {loading ? <DefaultLoader text={"Načítám data..."}/> : <>
                                    <div className="modal-header">
                                        <h3 className="text-xl font-semibold">{"Přiřazení do sekce"}</h3>
                                        <button
                                            className="modal-close btn btn-transparent"
                                            onClick={hide}>
                                            <FiX size={18} className="stroke-current"/>
                                        </button>
                                    </div>
                                    <Form onSubmit={send} render={({handleSubmit}) => (
                                        <>
                                            <div className={"m-2"}>
                                                <SectionSelectField name={"section"} label={"Sekce"}
                                                                    isMandatory={true}/>
                                            </div>
                                            <div className="modal-footer space-x-2">
                                                <Button disabled={saving} loading={saving} onClick={hide}
                                                        text={"Zrušit"}
                                                        icon={<FiX/>}/>
                                                <Button disabled={saving} loading={saving} onClick={handleSubmit}
                                                        text={"Uložit"} icon={<FiSave/>}/>
                                            </div>
                                        </>
                                    )}/>


                                </>}
                            </div>
                        </div>
                    </div>
                </Portal>
            )}
        </>
    )
}
